import React from 'react';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import {Fieldset} from "primereact/fieldset";
import DataTable from "../../shared/component/dataTable/DataTable";
import LicenseReportColumn from "../../shared/component/dataTable/columns/LicenseReportColumn.json";
import Dialog from "../../shared/component/dialog/Dialog";
import AppLicenseSave from "./AppLicenseSave";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import LicenseService from "../../shared/service/LicenseService";
import {Button, Col, Label, Row} from "reactstrap";
import MerchantBranchFilter from "./MerchantBranchFilter";
import {InputText} from "primereact/inputtext";


const defaultLicense = {
    id: null,
    licenseStatus: '',
    merchantBranchId: '',
    notes: '',
    eft: false,
    creditAddress: null,
    creditAddressUsed: null,
    creditDistance: null,
    creditDistanceUsed: null,
    appLicencesEntityList: []
};

export default class Licenses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            licenseList: [],
            merchantBranchList: [],
            license: Object.assign({}, defaultLicense),
            licenseTemp: Object.assign({}, defaultLicense),
        }
    }

    componentDidMount() {
        this.licenseService = new LicenseService();
        this.refreshDataTable();
    }

    render() {
        return (<div>
            <AvForm>
                {this.renderFilter()}
                {this.merchantLicenseList()}
            </AvForm>
        </div>);
    }

    closeDisplayDialogFilter = () => {
        this.setState({displayDialogFilter: false});
    }

    onDataSelectFilter = (data) => {
        this.setState({merchantBranchId: data.id});
        this.setState({merchantBranchName: data.tradingName});
        this.setState({displayDialogFilter: false});
    }

    renderFilter() {
        return (<div><Row>


            <Col>


            <span className="p-input-icon-right">
                <InputText style={{borderRadius: "10px", width: "240px"}} disabled value={this.state.merchantBranchName}
                           placeholder="Select Merchant Branch" onClick={() => this.setState({displayDialogFilter: true})}/>
                <i
                    className="pi pi-search"
                    style={{cursor: "pointer"}}
                    onClick={() => this.setState({displayDialogFilter: true})}
                />

                <i
                    className="pi pi-times"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                        this.setState({merchantBranchId: null, merchantBranchName: ''})

                    }}
                />

                <i
                    className="pi pi-refresh"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                        this.refDataTable.dt.filter(this.state.merchantBranchId, "merchantBranchId", "equals");
                        let filterElement = this.refDataTable.state.filterElement;
                        filterElement["merchantBranchId"] = this.state.merchantBranchId;
                        this.refDataTable.setState({filterElement: filterElement});
                        this.refDataTable.onModelFilterChangeWithNameAndValue("merchantBranchId", this.state.merchantBranchId, "long");

                    }}
                />

            </span>
            </Col>

            <Col>
                <Dialog visible={this.state.displayDialogFilter}
                        header={"Merchant Branch"}
                        onHide={() => this.setState({displayDialogFilter: false})}
                        style={{width: '80vw', height: '45vw'}}>

                    <MerchantBranchFilter
                        ref={(elem) => this.refModelCredentialsSave = elem}
                        onDataSelect={this.onDataSelectFilter}
                        closeDisplayDialog={this.closeDisplayDialogFilter}/>
                </Dialog>
            </Col>


        </Row>

        </div>)
    }

    merchantLicenseList() {
        return (
            <div>
                <Fieldset legend="Licenses">
                    <DataTable ref={(elem) => this.refDataTable = elem} fields={LicenseReportColumn.fields}
                               objectName={LicenseReportColumn.name}
                               dataURL="crm/merchant/license/all"
                               rows={10}
                               selectionMode="single" onDataSelect={this.onDataSelect}
                               loading={this.state.loading}/>


                    <Dialog visible={this.state.displayDialog}
                            header={"License"}
                            onHide={() => this.setState({displayDialog: false})}
                            style={{width: '60vw', height: '35vw'}}>

                        <AppLicenseSave
                            refreshDataTable={this.refreshDataTable} ref={(elem) => this.refModelSave = elem}
                            closeDisplayDialog={this.closeDisplayDialog}
                            appLicense={this.state.selectedAppLicense}
                            getMerchantBranchId={this.getMerchantBranchId}
                            saveAppLicense={(row) => {
                                this.licenseService.updateApplicence(row)
                                    .then((response) => {
                                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                            this.setState({
                                                licenseSaveButtonRender: false

                                            });
                                            this.refreshDataTable();
                                        }
                                    });


                            }}
                            deleteAppLicense={(id) => {
                                this.licenseService.deleteApplicence(id)
                                    .then((response) => {
                                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                            this.setState({
                                                licenseSaveButtonRender: false

                                            });
                                            this.refreshDataTable();
                                        }
                                    });


                            }}
                        />
                    </Dialog>

                </Fieldset>
            </div>)
    }

    getMerchantBranchId = () => {
        return this.state.license.merchantBranchId;
    }
    saveLicense = () => {
        let license = this.state.license;
        license["merchantBranchId"] = this.props.getMerchantBranchId();
        this.setState({license: license});

        if (!GeneralUtils.isNullOrEmpty(this.state.license.id)) {
            this.licenseService.update(this.state.license)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {

                        this.setState({
                            license: response,
                            licenseTemp: Object.assign({}, response),
                            licenseSaveButtonRender: false
                        })
                        this.props.refreshDataTable();

                    }
                });
        } else {
            this.licenseService.create(this.state.license)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {

                        this.setState({
                            license: response,
                            licenseTemp: Object.assign({}, response),
                            licenseSaveButtonRender: false
                        })
                        this.props.refreshDataTable();

                    }
                });
        }

    }
    refreshDataTable = () => {
        this.closeDisplayDialog();
        this.refDataTable.refresh();
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }

    onDataSelect = (data) => {
        this.refModelSave.setData(Object.assign({}, data));

        this.setState({
            displayDialog: true,
            license: data
        });

    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let mailSettings = this.state.mailSettings;
        mailSettings[e.target.name] = value;
        this.setState({mailSettings: mailSettings});
    }

}

