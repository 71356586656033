import React from 'react';
import {Col, Label, Row} from 'reactstrap';
import {AvFeedback, AvGroup} from 'availity-reactstrap-validation';
import {Button} from 'reactstrap';
import PropTypes from 'prop-types';
import GeneralUtils from "../../shared/utils/GeneralUtils";
import AvField from "../../shared/component/form/AvField";
import Translate from "../../shared/language/Translate";
import {Fieldset} from "primereact/fieldset";
import {toast} from "react-toastify";
import LicenseService from "../../shared/service/LicenseService";
import {Checkbox} from "primereact/checkbox";
import DataTableAll from "../../shared/component/dataTable/DataTableAll";
import LicenseColumn from "../../shared/component/dataTable/columns/LicenseColumn.json";
import Dialog from "../../shared/component/dialog/Dialog";
import AppLicenseAndCredentialsSave from "./AppLicenseAndCredentialsSave";

const defaultLicense = {
    id: null,
    licenseStatus: '',
    merchantBranchId: '',
    notes: '',
    eft: false,
    creditAddress: null,
    creditAddressUsed: null,
    creditDistance: null,
    creditDistanceUsed: null,
    appLicencesEntityList: []
};
export default class BranchLicenseSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            license: Object.assign({}, defaultLicense),
            licenseTemp: Object.assign({}, defaultLicense),
            pageMode: null
        }
        this.licenseService = new LicenseService();
    }

    render() {
        return (
            <div class="p-col-12">

                <Row>
                    <Col md="2">
                        <AvGroup>
                            <Label for="tradingName">
                                <Translate
                                    contentKey="merchantBranch.tradingName">Credit Address</Translate>
                            </Label>

                            <AvField
                                type="number"
                                value={this.state.license.creditAddress}
                                disabled={!this.state.licenseSaveButtonRender}
                                name="creditAddress"
                                min={100} max={1000000}
                                className="input-group"
                                onChange={this.__handleChange}
                                showButtons/>


                        </AvGroup>
                    </Col>

                    <Col md="2">
                        <AvGroup>
                            <Label for="tradingName">
                                <Translate
                                    contentKey="merchantBranch.tradingName">Credit Distance</Translate>
                            </Label>

                            <AvField
                                type="number"
                                value={this.state.license.creditDistance}
                                disabled={!this.state.licenseSaveButtonRender}
                                name="creditDistance"
                                min={100} max={1000000}
                                className="input-group"
                                onChange={this.__handleChange}
                                showButtons/>


                        </AvGroup>
                    </Col>


                    <Col md="2">
                        <AvGroup>
                            <Label for="tradingName">
                                <Translate
                                    contentKey="merchantBranch.tradingName">Credit Distance Used</Translate>
                            </Label>

                            <AvField
                                type="number"
                                value={this.state.license.creditDistanceUsed}
                                disabled={true}
                                name="creditDistanceUsed"
                                min={100} max={1000000}
                                className="input-group"
                                onChange={this.__handleChange}
                                showButtons/>


                        </AvGroup>
                    </Col>

                    <Col md="2">
                        <AvGroup>
                            <Label for="tradingName">
                                <Translate
                                    contentKey="merchantBranch.tradingName">Credit Address Used</Translate>
                            </Label>

                            <AvField
                                type="number"
                                value={this.state.license.creditAddressUsed}
                                disabled={true}
                                name="creditAddressUsed"
                                min={100} max={1000000}
                                className="input-group"
                                onChange={this.__handleChange}
                                showButtons/>


                        </AvGroup>
                    </Col>


                    <Col md="2">
                        <AvGroup>
                            <Label for="tradingName">
                                <Translate
                                    contentKey="merchantBranch.tradingName">Notes</Translate>
                            </Label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="notes"
                                disabled={!this.state.licenseSaveButtonRender}
                                validate={{
                                    maxLength: {
                                        value: 255,
                                        errorMessage: "You can enter up to 255 characters in this field."
                                    }
                                }}
                                value={this.state.license.notes}
                                onChange={this.__handleChange}/>
                        </AvGroup>


                    </Col>
                    <Col md="2">
                        <AvGroup>
                            <Label for="eft">
                                <Translate contentKey="eft">Eft&nbsp;</Translate>
                            </Label>

                            <Checkbox onChange={(e) => this.setChecked("eft", e.checked)}
                                      name="eft"
                                      disabled={!this.state.licenseSaveButtonRender}
                                      value={this.state.license.eft}
                                      checked={this.state.license.eft}></Checkbox>


                        </AvGroup>
                    </Col>
                </Row>


                {!this.state.licenseSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}} onClick={() => {
                        this.setState({licenseSaveButtonRender: true});
                    }}>
                        <Translate contentKey="entity.action.add">Edit</Translate>
                    </Button>}
                {this.state.licenseSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}} onClick={() => {
                        this.saveLicense();
                    }}>
                        <Translate contentKey="entity.action.add">Save</Translate>
                    </Button>
                }

                <Fieldset legend={"App License"}>
                    <Button color="outline-primary" style={{marginBottom: '5px'}} onClick={() => {
                        this.refModelSave.licenseSaveButtonRender();
                        this.refModelSave.clearData();
                        this.setState({displayDialog: true, selectedAppLicense: null});

                    }}>
                        <Translate contentKey="entity.action.add">Add</Translate>
                    </Button>

                    <DataTableAll ref={(elem) => this.refModelLicenseSave = elem}
                                  fields={LicenseColumn.fields}
                                  objectName={LicenseColumn.name}
                                  data={this.state.license.appLicencesEntityList}
                                  rows={5}
                                  selectionMode="single" onDataSelect={this.onDataSelect}
                    />

                    <Dialog visible={this.state.displayDialog}
                            header={"License"}
                            onHide={() => this.setState({displayDialog: false})}
                            style={{width: '80vw', height: '45vw'}}>

                        <AppLicenseAndCredentialsSave
                            refreshDataTable={this.refreshDataTable}
                            ref={(elem) => this.refModelSave = elem}
                            closeDisplayDialog={this.closeDisplayDialog}
                            appLicense={this.state.selectedAppLicense}
                            getMerchantBranchId={this.getMerchantBranchId}
                            deleteAppLicense={(id) => {
                                this.licenseService.deleteApplicence(id)
                                    .then((response) => {
                                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                            this.setState({
                                                licenseSaveButtonRender: false

                                            });
                                            this.props.refreshDataTable();
                                            this.closeDisplayDialog();
                                        }
                                    });


                            }}
                            saveAppLicense={(row) => {
                                if (row.id) {
                                    let license = this.state.license;
                                    const foundObject = license.appLicencesEntityList.find(item => item.id === row.id);
                                    Object.assign(foundObject, row);
                                    this.setState({license: license});
                                    this.saveLicense(license);
                                } else {
                                    let result = this.checkLicenses(row);
                                    if (result) {
                                        return toast.warn("Maximum one service name  per branch except 'Artpos'");
                                    } else {
                                        let license = this.state.license;
                                        license.appLicencesEntityList.push(row)
                                        this.setState({license: license});
                                        this.saveLicense(license);
                                    }

                                }
                                this.closeDisplayDialog();
                            }}
                        />
                    </Dialog>

                </Fieldset>


            </div>
        );
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }

    onDataSelect = (data) => {
        this.refModelSave.setData(Object.assign({}, data));
        this.setState({selectedAppLicense: data, displayDialog: true})

    }

    checkLicenses = (row) => {
        let license = this.state.license;
        var count = 0;
        if(license.appLicencesEntityList != null){
            for (var i = 0; i < license.appLicencesEntityList.length; i++) {
                if (license.appLicencesEntityList[i].serviceName == row.serviceName && row.serviceName != "ARTPOS") {
                    count++;
                }
            }
        }

        return count > 0 ? true : false;

    }

    setChecked = (name, value) => {
        let license = this.state.license;
        license[name] = value;
        this.setState({license: license});
    }

    getMerchantBranchId = () => {
        return this.props.getMerchantBranchId();
    }
    saveLicense = () => {
        let license = this.state.license;
        license["merchantBranchId"] = this.props.getMerchantBranchId();
        this.setState({license: license});

        if (!GeneralUtils.isNullOrEmpty(this.state.license.id)) {
            this.licenseService.update(this.state.license)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {

                        this.setState({
                            license: response,
                            licenseTemp: Object.assign({}, response),
                            licenseSaveButtonRender: false
                        })
                        this.props.refreshDataTable();

                    }
                });
        } else {
            this.licenseService.create(this.state.license)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {

                        this.setState({
                            license: response,
                            licenseTemp: Object.assign({}, response),
                            licenseSaveButtonRender: false
                        })
                        this.props.refreshDataTable();

                    }
                });
        }

    }

    setData = (data) => {
        this.setState({license: data, licenseTemp: Object.assign({}, data),});
    }

    clearData = () => {
        let obj = Object.assign({}, defaultLicense);
        this.setState({license: obj});
    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let license = this.state.license;
        license[e.target.name] = value;
        this.setState({license: license});
    }

}

BranchLicenseSave.propTypes = {
    license: PropTypes.object
};
