import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import Translate from "../../shared/language/Translate";
import GeneralUtils from '../../shared/utils/GeneralUtils';
import {Fieldset} from "primereact/fieldset";
import DepartmentColumn from "../../shared/component/dataTable/columns/DepartmentColumn.json";
import DataTableWithButton from "../../shared/component/dataTable/DataTableWithButton";
import DepartmentService from "../../shared/service/DepartmentService";
import ProductService from "../../shared/service/ProductService";
import DeposImageService from "../../shared/service/DeposImageService";

export default class DepartmentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            departmentList: []
        }
        this.departmentService = new DepartmentService();
        this.productService = new ProductService();
        this.deposImageService = new DeposImageService();
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.departmentService.findAll().then(response => this.setState({
            departmentList: response
        }));
    }

    render() {
        return (<div>
            <AvForm>
                {this.getDepartmentList()}
            </AvForm>
        </div>);
    }

    getDepartmentList() {

        return (<div class="p-col-12">

            <Fieldset legend="Departments">

                {GeneralUtils.checkPrivilege("post_product/department") &&
                    <Button color="outline-primary" style={{marginBottom: '5px'}} onClick={() => {
                        window.location.href = "#departmentSave?";
                    }}>
                        <Translate contentKey="entity.action.add">Add</Translate>
                    </Button>
                }

                <Button color="outline-primary" style={{marginBottom:'5px'}} onClick={() => {

                    this.productService.getMigrationImages()
                        .then((response) => {
                            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                this.setState({

                                });
                                alert(1);
                            }
                        });

                }}>
                    <Translate contentKey="entity.action.add">Images 1 </Translate>
                </Button>

                <Button color="outline-primary" style={{marginBottom:'5px'}} onClick={() => {

                    this.deposImageService.getMigrationImages()
                        .then((response) => {
                            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                this.setState({

                                });
                                alert(2);
                            }
                        });

                }}>
                    <Translate contentKey="entity.action.add">Depos Images 1 </Translate>
                </Button>

                {GeneralUtils.checkPrivilege("get_product/department") &&
                    <DataTableWithButton ref={(elem) => this.refDataTable = elem} fields={DepartmentColumn.fields}
                                         objectName={DepartmentColumn.name}
                                         data={this.state.departmentList}
                                         rows={10}
                                         selectionMode="single" onDataSelect={this.onDataSelect}
                                         loading={this.state.loading}/>
                }
            </Fieldset>


        </div>)
    };

    onDataSelect = (data) => {
        window.location.href = "#departmentSave?"+data.id;
    }


}

