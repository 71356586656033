import React from 'react';
import './OrderListPrimeReact.css';
import GeneralUtils from "../../shared/utils/GeneralUtils";
import WebMenuMemberService from "../../shared/service/WebMenuMemberService";
import {Button} from "primereact/button";
import {Button as ButtonReactStrap} from "reactstrap";
import {OrderCheckOut} from "../../shared/layaout/sigma/OrderCheckOut";
import ItemDetailsCheckOut from "./ItemDetailsCheckOut";
import {ButtonGroup, Col, Row} from "reactstrap";
import BasketService from "../../shared/service/BasketService";
import {PAGE_MODE, SERVER_URL, WEB_IMAGE_SERVER_URL} from "../../shared/utils/Constants";
import {ProgressBar} from 'primereact/progressbar';
import {AppFooterMemberMobile} from "../../shared/layaout/sigma/AppFooterMemberMobile";
import 'react-tabs-scrollable/dist/rts.css'
import MemberUserSecurityService from "../../shared/service/MemberUserSecurityService";
import {InputText} from 'primereact/inputtext';
import ModalConfirm from "../../shared/component/dialog/ModalConfirm";
import {Badge, Chip} from "@material-ui/core";
import {Card} from 'primereact/card';
import {OrderList} from 'primereact/orderlist';
import {BreadCrumb} from "primereact/breadcrumb";
import MarketPlace from "./MarketPlace";
import MerchantBranchService from "../../shared/service/MerchantBranchService";
import MerchantBranchSettingsService from "../../shared/service/MerchantBranchSettingsService";
import PageSettingsService from "../../shared/service/PageSettingsService";
import Dialog from "../../shared/component/dialog/Dialog";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {toast} from "react-toastify";
import AddressService from "../../shared/service/AddressService";
import MemberUserAddressService from "../../shared/service/MemberUserAddressService";

const defaultAddressInfo = {
    addressText: '',
    contactPhone: '',
    storeOpenOrCloseMessageText: '',
    tradingName: '',
    menuBorderColor: null,
    menuDisplayTemplate: 'Template1'
};

export default class DashboardMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shortcuts: [],
            products: [],
            crumbItems: [],
            displayDialog: false,
            hasDeliveryOrderType: false,
            servicableThisPostCode: false,
            loading: false,
            deliveryTypesList: [],
            addressInfo: defaultAddressInfo,
            postCode: "",
            postCodeTemp: "",
            filteredAddresses: [],
            isSetMenu: true,
            showItems: true,
            index: 0,
            searchText: "",
            orderType: "Collection",
            indexTemp: 0,
            selectedMenu: null
        };
        this.productItem = this.productItem.bind(this);
        this.webMenuMemberService = new WebMenuMemberService();
        this.basketService = new BasketService();
        this.memberUserSecurityService = new MemberUserSecurityService();
        this.merchantBranchService = new MerchantBranchService();
        this.merchantBranchSettingsService = new MerchantBranchSettingsService();
        this.pageSettingsService = new PageSettingsService();
        this.addressService = new AddressService();
        this.memberUserAddressService = new MemberUserAddressService();
    }

    componentDidUpdate(prevProps, prevState) {
        let branchName = GeneralUtils.getDomainName();
        if (!GeneralUtils.isNullOrEmpty(branchName)) {
            if (prevState.branchName == undefined) {
                this.merchantBranchService.getBranchIdByDomainName(branchName).then(response => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type) && response != 0) {
                        localStorage.setItem("merchantBranchId", response);
                        this.getLogo(response);
                        this.getDeliveryTypes();
                        this.getWebMenuList();
                        this.getBasketInfo();
                        this.setState({
                            branchName: branchName,
                        });
                    } else {
                        window.location.href = '/'
                        this.setState({
                            branchName: null,
                        });
                    }

                });

            } else if (prevState.branchName !== branchName) {
                this.setState({
                    branchName: branchName,
                });
                this.merchantBranchService.getBranchIdByDomainName(branchName).then(response => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type) && response != 0) {
                        localStorage.setItem("merchantBranchId", response);
                        this.getLogo(response)
                    } else {
                        window.location.href = '/'
                        localStorage.setItem("merchantBranchId", -1);
                        this.getLogo(-1)
                    }
                });
            }
        }


    }


    componentDidMount = () => {
        document.addEventListener("DOMContentLoaded", function () {
            document.body.addEventListener("click", function () {
                window.history.pushState(null, document.title, window.location.href);
            });
        });

        let that = this;
        window.addEventListener('popstate', function (event) {
            if (that.state.crumbItems.length > 0) {
                that.setState({
                    crumbItems: [], products: [],
                    shortcuts: [],
                    selectedCardId: null
                });
                that.getDeliveryTypes();
                that.getWebMenuList();
                const navigationEntries = performance.getEntriesByType("navigation");
                const navigationType = navigationEntries.length > 0 ? navigationEntries[0].type : "navigate";
                if (navigationType !== "back_forward") {
                    window.history.pushState(null, document.title, window.location.href);
                }

            } else {
                if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("appMode")) && localStorage.getItem("appMode") == "member") {
                    if (that.state.showItems == false) {
                        that.setState({showItems: true});
                    } else {
                        window.history.go(-1);
                    }

                }

            }

        });

        refreshToken(this.memberUserSecurityService);

        function refreshToken(service) {
            setInterval(function () {
                if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("appMode")) && localStorage.getItem("appMode") == "member") {

                    if (!GeneralUtils.isNullOrEmpty(GeneralUtils.getRefreshTokenFromCookie())) {
                        service.refreshToken().then(response => {
                            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                GeneralUtils.setJwtToCookie(response.accessToken, response.refreshToken, response.expireDate);
                            }
                        });
                    }
                }

            }, 1000 * 60 * 60 * 24);
        }


        if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("displayAddressString")) && localStorage.getItem("displayAddressString") != "undefined") {
            let postCode = localStorage.getItem("displayAddressString");
            this.setState({
                postCode: postCode, servicableThisPostCode: true, postCodeTemp: localStorage.getItem("postCode")
            });

        } else {
            if (GeneralUtils.isAuthenticated()) {
                this.getUserCurrentAddress()
            }


        }

        if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("merchantBranchId"))) {
            this.getDeliveryTypes();
            this.getWebMenuList();
            this.getBasketInfo();
            this.getLogo(localStorage.getItem("merchantBranchId"))
        }

        this.getProductsForHomeParent();


    }

    getProductsForHomeParent = () => {

        if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("appMode")) && localStorage.getItem("appMode") == "member") {
            if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("merchantBranchId"))) {

                this.webMenuMemberService.getWebMenuItemsByParentId(localStorage.getItem("merchantBranchId"), -1).then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.status)) {
                        this.setState({
                            products: response,
                            loading: false
                        });
                    } else {
                        this.setState({
                            products: [],
                            loading: false
                        });
                    }
                });


            }
        }

    }

    async getDeliveryTypes() {
        var array = [];
        let orderType = null;
        var hasDelivery = false;
        var hasDeliveryOrderType = false;
        const response = (await this.merchantBranchSettingsService.findByBranchIdForMember(localStorage.getItem("merchantBranchId")));
        if (localStorage.getItem("WEB_ORDER_LICENSE") == "true") {

            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.status)) {
                hasDeliveryOrderType = response.hasDeliveryOrderType;
                if (!GeneralUtils.isNullOrEmpty(response.webOrderPaymentOptions)) {

                    var nameArr = response.webOrderPaymentOptions.split(',');
                    for (var i = 0; i < nameArr.length; i++) {
                        if (nameArr[i].indexOf("DELIVERY") > 0) {
                            array.push({
                                name: "Delivery", id: "Delivery"
                            });
                            orderType = "Delivery";
                            hasDelivery = true;
                            break;
                        }

                    }

                    for (var i = 0; i < nameArr.length; i++) {
                        if (nameArr[i].indexOf("COLLECTION") > 0) {
                            array.push({
                                name: "Collection", id: "Collection"
                            });

                            if (hasDelivery) {
                                orderType = "Delivery";
                            } else {
                                orderType = "Collection";
                            }

                            break;
                        }

                    }
                }
            }


        }

        if (localStorage.getItem("TABLE_ORDER_LICENSE") == "true") {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.status)) {
                hasDeliveryOrderType = response.hasDeliveryOrderType;
                if (!GeneralUtils.isNullOrEmpty(response.tableOrderPaymentOptions)) {
                    array.push({
                        name: "Table Order", id: "Table Order"
                    });
                    orderType = "Table Order";
                    if (GeneralUtils.isNullOrEmpty(localStorage.getItem("tableNo")) && localStorage.getItem("WEB_ORDER_LICENSE") == "true") {
                        if (hasDelivery) {
                            orderType = "Delivery";
                        } else {
                            orderType = "Collection";
                        }
                    }
                }
            }

        }
        if (!GeneralUtils.__isValueEmpty(localStorage.getItem("orderType")) && localStorage.getItem("orderType") != "undefined") {
            orderType = localStorage.getItem("orderType");
        }
        localStorage.setItem("orderType", orderType);
        this.setState({orderType: orderType, deliveryTypesList: array, hasDeliveryOrderType: hasDeliveryOrderType});
    }


    getWebMenuList = () => {

        if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("appMode")) && localStorage.getItem("appMode") == "member") {
            if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("merchantBranchId"))) {
                this.setState({loading: true});
                this.webMenuMemberService.getActiveWebMenuShortcutsForMemberByBranchId(localStorage.getItem("merchantBranchId")).then(response => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            shortcuts: response,
                            loading: false
                        });

                    } else {
                        this.setState({
                            shortcuts: [],
                            loading: false
                        });
                    }
                });
            }
        }


    }

    setQuantitiesToCart(response) {
        let basketDetailList = response.basketDetailList;
        let products = this.state.products;
        for (var i = 0; i < products.length; i++) {
            products[i].basketQuantity = null;
            for (var j = 0; j < basketDetailList.length; j++) {

                if (products[i].webMenuBssId == basketDetailList[j].webMenuBssId) {
                    if (GeneralUtils.isSetMenu(basketDetailList[j])) {
                        let sum = 0;
                        basketDetailList.forEach(item => {
                            if (item.webMenuBssId == products[i].webMenuBssId) {
                                sum += item.quantity;
                            }
                        })
                        products[i].basketQuantity = sum;
                    } else {
                        products[i].basketQuantity = basketDetailList[j].quantity;
                    }

                }
            }
        }
        this.setState({products: products});
    }

    getBasketInfo = async () => {
        if ((GeneralUtils.hasWebOrderLicense() || GeneralUtils.hasTableOrderLicense())) {
            const response = (await this.basketService.findBasketCreatedByMerchantBranchId(localStorage.getItem("merchantBranchId"), null));
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                this.setQuantitiesToCart(response);
                if (this.refOrderCheckout != null) {
                    this.refOrderCheckout.setPageMode(PAGE_MODE.CREATE);
                    this.refOrderCheckout.setData(response);
                    if (this.refAppFooterMember != null) {
                        this.refAppFooterMember.refreshData();
                    }
                }
            }
        }
    }

    productItemTemplate2(item) {

        let maxWidth = "11rem";

        if (GeneralUtils.isMobile()) {
            maxWidth = "8rem";
        }


        return <div style={{overflow: 'hidden !important;', textOverflow: 'ellipsis !important;'}}>

            <div className={"product-item2"} style={{
                borderLeft: this.getBorderColor()
            }}>

                <Row>


                    <div className="image-container">
                        <img
                            src={!GeneralUtils.isNullOrEmpty(item.webImagePath) ? WEB_IMAGE_SERVER_URL + item.webImagePath : "assets/layout/images/white.png"}
                            onError={(e) => e.target.src = "assets/layout/images/white.png"}
                            alt={item.name}/>

                    </div>
                </Row>
                <div style={{
                    position: "sticky",
                    top: "80%",
                    textAlign: "center",
                    maxWidth: maxWidth,
                }}>

                    <Row>

                        <Col>
                            {!GeneralUtils.isNullOrEmpty(item.basketQuantity) &&
                                <h6 style={{fontWeight: "bold"}}>{item.name}&nbsp;&nbsp;<Chip
                                    label={item.basketQuantity}
                                    style={{
                                        backgroundColor: this.getBackGroundColor(),
                                        zoom: "0.7",
                                        color: "white"
                                    }}/>&nbsp;&nbsp;</h6>
                            }
                            {GeneralUtils.isNullOrEmpty(item.basketQuantity) &&
                                <h6 style={{fontWeight: "bold"}}>{item.name}</h6>
                            }

                        </Col>
                    </Row>


                    <Row>
                        <Col>

                            {!GeneralUtils.isNullOrEmpty(item.price) &&
                                <h6>£{item.price.toFixed(2)}</h6>}
                            {!GeneralUtils.isNullOrEmpty(item.oldPrice) && <h6>
                                <del> £{item.oldPrice.toFixed(2)} </del>
                            </h6>}

                        </Col>

                    </Row>

                    <Row>


                        <div style={{float: "right", marginLeft: "auto"}}>
                            {this.renderAddToCartButtons(item)}

                        </div>

                    </Row>


                </div>


            </div>

        </div>

    }

    renderAddToCartButtons(item) {
        let marginRight = "0.5rem";
        if (GeneralUtils.isMobile()) {
            marginRight = "0.5rem";
        }
        return <>
            <div>
                {GeneralUtils.isAuthenticated() && (GeneralUtils.hasTableOrderLicense() || GeneralUtils.hasWebOrderLicense()) && this.renderAddToChartButton(item)
                }

            </div>

            <div>
                {!GeneralUtils.isAuthenticated() && (GeneralUtils.hasTableOrderLicense() || GeneralUtils.hasWebOrderLicense()) && this.renderAddToChartButton(item)
                }

            </div>

            {(!GeneralUtils.hasTableOrderLicense() && !GeneralUtils.hasWebOrderLicense() && GeneralUtils.hasWebMenuLicense()) && (GeneralUtils.isSetMenu(item)) &&
                <div>
                    <Button type="button" icon={"pi pi-info-circle"}
                            style={{
                                border: '0px solid #1982D9',
                                boxShadow: '0px 0px 0px 0px #ccc',
                                marginRight: this.state.addressInfo.menuDisplayTemplate == "Template2" ? marginRight : 0,
                                zoom: '1.5'
                            }}
                            onClick={() => {
                                this.itemsDetail.clearData(Object.assign({}, item), true);
                                this.setState({displayDialog: true, isSetMenu: true})
                            }}></Button>
                </div>
            }
        </>;
    }

    productItemTemplate1(item) {
        let classNameSubTitle = "pi pi-star product-category-icon";
        let className = "pi pi-chevron-right product-category-icon";

        return <div style={{overflow: 'hidden !important;', textOverflow: 'ellipsis !important;'}}>
            <div className={"product-item"} style={{borderLeft: this.getBorderColor()}}>

                <div className="image-container">
                    <img  src={!GeneralUtils.isNullOrEmpty(item.webImagePath) ? WEB_IMAGE_SERVER_URL + item.webImagePath : "assets/layout/images/white.png"}
                          onError={(e) => e.target.src = "assets/layout/images/white.png"}
                         alt={item.name}/>
                </div>


                <div className="product-list-detail">
                    <Row>


                        {!GeneralUtils.isNullOrEmpty(item.basketQuantity) &&


                            <h6>&nbsp;&nbsp;&nbsp;<Chip label={item.basketQuantity}
                                                        style={{
                                                            backgroundColor: this.getBackGroundColor(),
                                                            zoom: "0.7",
                                                            color: "white"
                                                        }}/>&nbsp;{item.name}&nbsp;&nbsp;&nbsp;</h6>
                        }
                        {GeneralUtils.isNullOrEmpty(item.basketQuantity) &&
                            <h6>&nbsp;&nbsp;&nbsp;{item.name}&nbsp;&nbsp;&nbsp;</h6>
                        }


                    </Row>


                    {!GeneralUtils.isNullOrEmpty(item.subTitle) && <div>
                        <i className={classNameSubTitle}></i>
                        <span className="mb-0">{GeneralUtils.sliceString(item.subTitle, 60)}</span>
                    </div>}

                    {!GeneralUtils.isNullOrEmpty(item.grpContentText) && <div>
                        <i className={className}></i>
                        <span>{GeneralUtils.sliceString(item.grpContentText, 60)}</span>
                    </div>}


                </div>
                <div className="product-list-action">
                    {!GeneralUtils.isNullOrEmpty(item.price) && <h6 className="mb-2">£{item.price.toFixed(2)}</h6>}
                    {!GeneralUtils.isNullOrEmpty(item.oldPrice) && <h6 className="mb-2">
                        <del> £{item.oldPrice.toFixed(2)} </del>
                    </h6>}
                    {this.renderAddToCartButtons(item)}

                </div>
            </div>

        </div>

    }

    productItem(item) {

        if (this.state.addressInfo.menuDisplayTemplate == "Template2") {
            return this.productItemTemplate2(item);
        } else {
            return this.productItemTemplate1(item);
        }

    }

    addProductToCart = (item) => {
        this.refOrderCheckout.addItem(item)
        this.setState({displayDialog: false})
    }

    addItemToCart(item) {

        var validations = this.checkDeliveryPostCodeIsValid();
        if (GeneralUtils.isNullOrEmpty(validations)) {
            if (GeneralUtils.isSetMenu(item)

            ) {
                this.itemsDetail.clearData(Object.assign({}, item), false);
                this.setState({displayDialog: true, isSetMenu: true})
            } else {
                this.itemsDetail.clearData(Object.assign({}, item), false);
                this.setState({displayDialog: true, isSetMenu: false})
            }
        } else {
            return toast.warn(validations);
        }


    }

    showConfirmDialog = (item) => {
        this.merchantBranchSettingsService.isStoreClosed(localStorage.getItem("merchantBranchId")).then((response) => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                if (item.ageRestricted) {
                    this.basketService.checkAgeRestricted(localStorage.getItem("merchantBranchId")).then((response) => {
                        if (response == false) {
                            this.setState({
                                displayConfirmDialog: true,
                                item: item,
                                noticeResponse: "The item you want to add has an age limit. Are you over 18?"
                            });
                        } else {
                            this.addItemToCart(item)
                        }
                    });
                } else {
                    this.addItemToCart(item)
                }
            }

        });

    }

    ageRestricted = (item) => {
        this.basketService.ageRestricted(localStorage.getItem("merchantBranchId")).then((response) => {
            if (!GeneralUtils.isNullOrEmpty(response)) {
                this.addItemToCart(item);
            }
        });
    }

    renderAddToChartButton(item) {
        let marginRight = "0.5rem";
        if (GeneralUtils.isMobile()) {
            marginRight = "0.5rem";
        }
        let form = [];
        form.push(<Button type="button" icon={"pi pi-plus"}
                          style={{
                              border: '0px solid #1982D9',
                              boxShadow: '0px 0px 0px 0px #ccc',
                              zoom: '1.5',
                              marginRight: this.state.addressInfo.menuDisplayTemplate == "Template2" ? marginRight : 0,
                          }}
                          onClick={() => {
                              this.showConfirmDialog(item);
                          }}></Button>);

        return form;
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }

    openItemDetails = (data) => {
        this.itemsDetail.setData(Object.assign({}, data));
        this.setState({displayDialog: true, isSetMenu: true})
    }

    addSetMenuItem = (item) => {
        this.refOrderCheckout.addItemSetMenu(item);
        this.setState({displayDialog: false});
    }

    __handleChangeSearchText = async (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        this.setState({searchText: value});
        if (!GeneralUtils.isNullOrEmpty(value) && value.length > 3) {
            this.setState({index: -1});
            this.setState({loading: true, products: []});

            if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("appMode")) && localStorage.getItem("appMode") == "member") {
                const response = (await this.webMenuMemberService.findBySearchText(localStorage.getItem("merchantBranchId"), value));
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.status)) {
                    this.setState({
                        products: response,
                        loading: false
                    });
                } else {
                    this.setState({
                        products: [],
                        loading: false
                    });
                }
            }

        } else {
            if (this.state.index != this.state.indexTemp) {
                this.setState({index: this.state.indexTemp});
                this.setState({loading: true, products: []});
                if (this.state.indexTemp < this.state.crumbItems.length) {
                    if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("appMode")) && localStorage.getItem("appMode") == "member") {
                        const response = (await this.webMenuMemberService.getWebMenuItemsByParentId(localStorage.getItem("merchantBranchId"), this.state.crumbItems[this.state.indexTemp].id));

                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.status)) {

                            this.setState({
                                products: response,
                                loading: false
                            });

                        } else {
                            this.setState({
                                products: [],
                                loading: false
                            });
                        }
                    }

                } else {
                    this.getWebMenuList();
                }

            }


        }

    }

    async getLogo(merchantBranchId) {

        if (merchantBranchId != null && merchantBranchId != -1) {
            const response = (await this.pageSettingsService.getImage(merchantBranchId, "LOGO"));

            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.status)) {
                if (!GeneralUtils.isNullOrEmpty(response.file)) {
                    this.setState({logo: GeneralUtils.convertForImageSource(response.file, response.fileType, response.fileName)});
                }
            }
            this.merchantBranchService.getStoreInfo(merchantBranchId).then(response => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({addressInfo: response});

                    }
                }
            )
        }


    }

    renderStoreInfo() {
        return (
            <div><i class="material-icons" style={{fontSize: "14px"}}>location_on</i>
                <span>&nbsp;{this.state.addressInfo.addressText}
        </span>
            </div>);

    }

    renderDeliveryInfo() {
        if (!GeneralUtils.isNullOrEmpty(this.state.addressInfo.minimumDeliveryDeliveryDuration) && !GeneralUtils.isNullOrEmpty(this.state.addressInfo.maximumDeliveryDeliveryDuration)) {
            return (
                <div><i class="material-icons" style={{fontSize: "14px"}}>schedule</i>
                    <span>&nbsp;{this.state.addressInfo.minimumDeliveryDeliveryDuration}&nbsp;-&nbsp;{this.state.addressInfo.maximumDeliveryDeliveryDuration}&nbsp;mins
        </span>
                </div>);
        }


    }

    renderStoreContactInfo() {

        if (!GeneralUtils.isNullOrEmpty(this.state.addressInfo.contactEmail)) {

            return (
                <div>

                    <p></p>
                    <i class="material-icons" style={{fontSize: "14px"}}>mail</i>
                    <span>&nbsp;{this.state.addressInfo.contactEmail}
        </span>
                </div>);


        }

    }

    renderStoreInfoAndSearchText() {

        let form = [];
        let marginTopSearchButton = 0;
        if (this.state.hasDeliveryOrderType && this.state.orderType == "Delivery") {
            marginTopSearchButton = 0;
        } else {
            marginTopSearchButton = 10;
        }
        let height = 90;
        let width = 90;
        let topImage = -40;
        let top = -50;
        let imageCardWidth = "100%";
        let searchTextTop = "-60px"
        if (GeneralUtils.isMobile()) {
            height = 50;
            width = 50;
            topImage = -25;
            top = -10;
            imageCardWidth = "%95";
            searchTextTop = "-10px"
        }
        let header = null;
        if (!GeneralUtils.isNullOrEmpty(this.state.logo)) {
            header = (
                <img alt="Card" src={this.state.logo} style={{
                    height: height + 'px',
                    width: width + 'px',
                    alignContent: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    position: "relative",
                    top: topImage,
                    zIndex: 10
                }}/>
            );
        }


        form.push(
            <div align={"center"} style={{
                borderWidth: "0",
                top: top + "px",
                width: imageCardWidth,
                margin: "auto",
                position: "relative",
                zIndex: 10
            }}>
                <Card style={{borderLeft: "none"}} title={this.state.addressInfo.tradingName}
                      header={header} className="md:w-5rem">
                    <p className="m-0">
                        {this.state.addressInfo.storeOpenOrCloseMessageText}<p></p>
                        {this.renderStoreInfo()}<p></p>
                        {this.renderDeliveryInfo()}<p></p>
                        {this.renderStoreContactInfo()}
                    </p>
                </Card></div>);

        form.push(<p></p>);
        form.push(<div
            style={{
                backgroundColor: '#FFFFFF', top: searchTextTop,
                position: "relative",
            }}>
            <div align={"center"}>

                {this.state.hasDeliveryOrderType &&
                    <div align={"center"} style={{borderRadius: "6px", width: "400px"}}>
                        {this.renderDeliveryTypes()}

                    </div>
                }
                {this.state.hasDeliveryOrderType && this.state.orderType == "Delivery" &&
                    <div align={"center"} style={{borderRadius: "10px", width: "300px", marginTop: "14px"}}>


                        <Dropdown ref={elem => this.dropDownAddress = elem}
                                  value={this.state.postCode}
                                  name="postCode"
                                  style={{borderRadius: "6px"}}
                                  showOnFocus={false}
                                  className="form-control"
                                  disabled={false}
                                  options={this.state.filteredAddresses}
                                  onChange={this.__handleChangeAddress} optionLabel="displayString"
                                  editable placeholder="Postcode"/>

                        <span className="pi pi-search"
                              style={{fontSize: "1.50rem", position: "relative", left: "160px", top: "-25px"}}
                              onClick={(e) => {
                                  this.searchAddress(e);
                              }}></span>


                    </div>
                }

                <InputText value={this.state.searchText}
                           style={{borderRadius: "6px", width: "300px", marginTop: marginTopSearchButton + "px"}}
                           placeholder="Search"
                           onChange={this.__handleChangeSearchText}/>
            </div>


        </div>);


        return form;

    }

    setDeliveryAddressDetailToLocalStorage(data) {
        localStorage.setItem("postCode", data.postCode);
        localStorage.setItem("description", data.postCode);
        localStorage.setItem("addressLine1", data.addressLines[0]);
        localStorage.setItem("addressLine2", data.addressLines[1]);
        localStorage.setItem("addressLine3", data.addressLines[2]);
        localStorage.setItem("addressLine4", data.addressLines[3]);
        localStorage.setItem("city", data.postTown);
        localStorage.setItem("displayAddressString", data.displayString);
    }


    __handleChangeAddress = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let postCodeTemp = null;

        if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("postCodeTemp")) && localStorage.getItem("postCodeTemp") != "undefined") {
            postCodeTemp = localStorage.getItem("postCodeTemp");
        }
        if (GeneralUtils.isNullOrEmpty(postCodeTemp) && value.postCode != undefined && !GeneralUtils.isNullOrEmpty(value)) {
            this.setState({postCode: value});
            this.setState({servicableThisPostCode: false});
            this.addressService.isServicableThisPostCode(localStorage.getItem("merchantBranchId"), value.postCode).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.status) && GeneralUtils.isNullOrEmpty(response.message)) {
                    this.setDeliveryAddressDetailToLocalStorage(value);
                    this.setState({servicableThisPostCode: response, postCodeTemp: value.postCode});

                } else {
                    this.setState({servicableThisPostCode: false});
                }

            });


        } else if (!GeneralUtils.isNullOrEmpty(postCodeTemp) && value.postCode != undefined && !GeneralUtils.isNullOrEmpty(value)) {
            this.setState({postCode: postCodeTemp, postCodeTemp: postCodeTemp});
            this.setState({servicableThisPostCode: false});
            this.addressService.isServicableThisPostCode(localStorage.getItem("merchantBranchId"), postCodeTemp).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.status) && GeneralUtils.isNullOrEmpty(response.message)) {
                    this.setDeliveryAddressDetailToLocalStorage(value);
                    this.setState({servicableThisPostCode: response});

                } else {
                    this.setState({servicableThisPostCode: false});
                }
            });
        } else {
            this.setState({servicableThisPostCode: false});
            this.setState({postCode: value, postCodeTemp: value});
        }


    }

    async getUserCurrentAddress() {
        let postCode = null;
        const response = (await this.memberUserAddressService.findAll());
        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.status)) {
            response.forEach(itemSub => {
                if (itemSub.defaultAddress) {
                    postCode = itemSub.postCode;
                }
            });
        }
        if (!GeneralUtils.isNullOrEmpty(postCode) && this.state.orderType == "Delivery") {
            this.addressService.isServicableThisPostCode(localStorage.getItem("merchantBranchId"), postCode).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.status) && GeneralUtils.isNullOrEmpty(response.message)) {
                    localStorage.setItem("postCode", postCode);
                    this.setState({servicableThisPostCode: response, postCode: postCode});

                } else {
                    this.setState({servicableThisPostCode: false, postCode: postCode});
                }


            });
        }
    }

    searchAddress = (e) => {
        let postCode = null;

        if (this.state.postCode != null && this.state.postCode.length >= 6) {
            postCode = this.state.postCode;
        }
        if (this.state.postCodeTemp != null && this.state.postCodeTemp.length >= 6) {
            postCode = this.state.postCodeTemp;
        }

        if (postCode != null) {
            this.addressService.getAddresses(localStorage.getItem("merchantBranchId"), postCode).then(response => {
                this.setState({filteredAddresses: response});
                if (response.length > 0) {
                    e.which = 99;
                    this.dropDownAddress.onInputKeyDown(e);
                } else {

                    this.addressService.isServicableThisPostCode(localStorage.getItem("merchantBranchId"), postCode).then(response => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.status) && GeneralUtils.isNullOrEmpty(response.message)) {

                            localStorage.setItem("postCode", postCode);
                            this.setState({servicableThisPostCode: response});

                        } else {
                            this.setState({servicableThisPostCode: false});
                        }


                    });
                    return toast.info("No Available Address");
                }


            });

        } else {
            this.setState({filteredAddresses: [], servicableThisPostCode: false});
        }

    }

    onChangeDeliveryType(value) {
        this.setState({orderType: value})
        localStorage.setItem("orderType", value);

    }

    showDeliveryType(type) {
        var showDeliveryType = false;
        for (var i = 0; i < this.state.deliveryTypesList.length; i++) {
            if (this.state.deliveryTypesList[i].name == type) {
                showDeliveryType = true;
            }
        }

        return showDeliveryType
    }

    renderDeliveryTypes() {
        let form = [];
        form.push(<ButtonGroup style={{display: "flex"}}>
            {this.showDeliveryType("Delivery") && <ButtonReactStrap
                color="primary"
                outline
                onClick={(e) => {
                    //this.setState({servicableThisPostCode: false, postCode: null, postCodeTemp: null});
                    this.onChangeDeliveryType("Delivery")
                }}
                active={this.state.orderType === "Delivery"}
            >
                Delivery
            </ButtonReactStrap>}
            {this.showDeliveryType("Collection") && <ButtonReactStrap
                color="primary"
                outline
                onClick={(e) => {
                    //this.setState({servicableThisPostCode: false, postCode: null, postCodeTemp: null});
                    this.onChangeDeliveryType("Collection")
                }}
                active={this.state.orderType === "Collection"}
            >
                Collection
            </ButtonReactStrap>}
            {this.showDeliveryType("Table Order") && <ButtonReactStrap
                color="primary"
                outline
                onClick={(e) => {
                    //this.setState({servicableThisPostCode: false, postCode: null, postCodeTemp: null});
                    this.onChangeDeliveryType("Table Order")
                }}
                active={this.state.orderType === "Table Order"}
            >
                Table Order
            </ButtonReactStrap>}


        </ButtonGroup>);

        return form;
    }

    renderMenu = () => {
        var spinnerStyle = {
            height: '0.5em'
        }
        return (<div style={{position: "relative", top: "-100px"}}>
            <ModalConfirm header='Warning' ok='Ok'
                          onCancelClick={() => this.setState({displayConfirmDialog: false})}
                          onOkClick={() => this.ageRestricted(this.state.item)}
                          show={this.state.displayConfirmDialog}
                          message={this.state.noticeResponse}/>

            <Row>

                {this.state.showItems &&
                    <Col md={8}>
                        {this.renderStoreInfoAndSearchText()}
                        {this.renderItems()}
                    </Col>

                }

                {(GeneralUtils.hasWebOrderLicense() || GeneralUtils.hasTableOrderLicense()) &&
                    <Col md={4}>
                        {this.renderCheckOut()}
                    </Col>}
            </Row>
            <Dialog visible={this.state.displayDialog}
                    closeOnEscape={true}
                    closable={true}
                    onHide={() => this.setState({displayDialog: false})}
                    style={this.state.isSetMenu ? GeneralUtils.getDialogStyleForDialog() : GeneralUtils.getDialogStyleForProduct()}>

                <ItemDetailsCheckOut ref={(elem) => this.itemsDetail = elem}
                                     onHide={() => this.closeDisplayDialog}
                                     addSetMenuItem={this.addSetMenuItem}
                                     addProduct={this.addProductToCart}
                                     closeDisplayDialog={this.closeDisplayDialog}/>

            </Dialog>
            {(GeneralUtils.hasWebOrderLicense() || GeneralUtils.hasTableOrderLicense()) && this.state.showItems == true &&
                <AppFooterMemberMobile ref={(elem) => this.refAppFooterMember = elem}
                                       viewcart={this.viewcart}></AppFooterMemberMobile>
            }
            {false&& <ProgressBar mode="indeterminate" style={spinnerStyle}/>}

        </div>);
    }

    render() {
        let branchName = this.state.branchName;
        if (!GeneralUtils.isNullOrEmpty(branchName)) {
            return this.renderMenu()
        } else {
            return (<MarketPlace></MarketPlace>);
        }

    }

    viewcart = () => {
        this.setState({showItems: false});

    }
    hidecart = () => {
        this.setState({showItems: true});

    }

    renderCheckOut() {
        let isMobile = !GeneralUtils.isMobile() || this.state.showItems == false;
        return (
            <div style={{
                zIndex: 10,
                position: "sticky",
                top: "46px",
                marginTop: "-72px",
                display: (isMobile) ? 'block' : 'none',
                right: GeneralUtils.isMobile() ? "0" : "-40px"
            }}>
                <OrderCheckOut ref={(elem) => this.refOrderCheckout = elem}
                               getBasketInfo={this.getBasketInfo}
                               refreshBasket={this.getBasketInfo}
                               checkDeliveryPostCodeIsValid={this.checkDeliveryPostCodeIsValid}
                               hidecart={this.hidecart}
                               openItemDetails={this.openItemDetails}></OrderCheckOut>
            </div>

        )

    }

    checkDeliveryPostCodeIsValid = () => {
        if (this.state.orderType == "Delivery") {
            if (GeneralUtils.isNullOrEmpty(this.state.postCode)) {
                return "Select a postCode";
            }

            if (this.state.servicableThisPostCode == false) {
                return "We’re sorry, but the restaurant does not currently deliver to this address. Please choose to order via collection or from another restaurant.";
            }

        }

    }

    renderCardImage(item) {
        let form = [];
        let source = "/assets/layout/images/white.png";
        let style = {
            objectFit: 'cover',
            maxHeight: '80px',
            maxWidth: '80px',
            height: '80px',
            width: '80px',
            marginTop: '15px',
            marginBottom: '5px',
            borderRadius: "5px "
        };

        form.push(
            <div className="image-container">
                <img
                    style={
                        style
                    }
                    onError={(e) => e.target.src = "assets/layout/images/white.png"}
                    src={!GeneralUtils.isNullOrEmpty(item.webImagePath) ? WEB_IMAGE_SERVER_URL + item.webImagePath : "assets/layout/images/white.png"}
                    alt={item.name}/>
            </div>);


        return form;
    }

    refreshCrumbItems(itemSub) {
        let crumbItems = this.state.crumbItems;
        const objWithIdIndex = crumbItems.findIndex((obj) => obj.id === itemSub.id);
        if (objWithIdIndex > -1) {
            crumbItems.length = objWithIdIndex + 1;
        }
        this.setState({crumbItems: crumbItems, selectedCardId: itemSub.id});
        this.getItemsFromDb(itemSub);
    }

    async getItemsFromDb(itemSub) {

        if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("appMode")) && localStorage.getItem("appMode") == "member") {
            if (itemSub != null) {
                this.setState({loading: true});
                if (itemSub.lastShortcuts == false) {
                    this.webMenuMemberService.getActiveSubWebMenuShortcutsForMemberByParentId(localStorage.getItem("merchantBranchId"), itemSub.id).then(data => this.setState({
                        shortcuts: data,
                        loading: false,
                        selectedMenu: itemSub.id
                    }));
                }
                const response = (await this.webMenuMemberService.getWebMenuItemsByParentId(localStorage.getItem("merchantBranchId"), itemSub.id));
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.status)) {
                    this.setState({
                        products: response,
                        loading: false,
                        selectedMenu: itemSub.id
                    })
                    this.getBasketInfo()
                }


            }
        }

    }

    refreshItems(itemSub) {
        let crumbItems = this.state.crumbItems;
        if (crumbItems.length > 0 && crumbItems[crumbItems.length - 1].lastShortcuts) {
            crumbItems = crumbItems.slice(0, crumbItems.length - 1);
            crumbItems.push({
                label: itemSub.name, id: itemSub.id, lastShortcuts: itemSub.lastShortcuts, command: (e) => {
                    this.refreshCrumbItems(itemSub)
                }
            })
        } else {
            crumbItems.push({
                label: itemSub.name, id: itemSub.id, lastShortcuts: itemSub.lastShortcuts, command: (e) => {
                    this.refreshCrumbItems(itemSub)
                }
            })
        }

        this.setState({crumbItems: crumbItems, selectedCardId: itemSub.id});
        this.getItemsFromDb(itemSub)
    }

    getBorderColor() {
        let borderLeft = "5px solid #00bcd4";

        if (!GeneralUtils.isNullOrEmpty(this.state.addressInfo.menuBorderColor)) {
            borderLeft = "5px solid #" + this.state.addressInfo.menuBorderColor
        }
        return borderLeft;
    }

    getBackGroundColor() {
        let borderLeft = "#00bcd4";

        if (!GeneralUtils.isNullOrEmpty(this.state.addressInfo.menuBorderColor)) {
            borderLeft = "#" + this.state.addressInfo.menuBorderColor
        }
        return borderLeft;
    }

    renderCard() {
        let width = 17;
        let height = 13;
        let widthLastShortcuts = 20;

        if (GeneralUtils.isMobile()) {
            width = 16;
            widthLastShortcuts = 8;
            height = 14;
        }
        let form = [];
        if (this.state.shortcuts != undefined && !GeneralUtils.isNullOrEmpty(this.state.shortcuts) && this.state.shortcuts.length > 0) {
            this.state.shortcuts.forEach(itemSub => {
                if (itemSub.lastShortcuts == false) {
                    form.push(
                        <div style={{
                            paddingTop: "20px", paddingLeft: "10px",
                            fontSize: "30px",
                            textAlign: "center"
                        }} onClick={() => this.refreshItems(itemSub)} id={itemSub.id}

                        >
                            <Card hoverable header={this.renderCardImage(itemSub)}
                                  title={this.state.selectedCardId == null || this.state.selectedCardId == itemSub.id ? itemSub.name : null}
                                  subTitle={this.state.selectedCardId == null || this.state.selectedCardId == itemSub.id ? null : itemSub.name}
                                  style={{
                                      width: GeneralUtils.isMobile() ? width + "em" : width + "vw",
                                      height: height + 'em',
                                      borderLeft: this.getBorderColor(),
                                  }}>

                            </Card>
                        </div>
                    );
                } else {
                    form.push(
                        <div style={{
                            paddingLeft: "20px", paddingRight: "20px", paddingTop: "50px", paddingBottom: "20px",
                            fontSize: "30px",
                            textAlign: "center"
                        }} onClick={() => this.refreshItems(itemSub)} id={itemSub.id}
                        >
                            <Card className={"check"} hoverable
                                  title={this.state.selectedCardId == null || this.state.selectedCardId == itemSub.id ? itemSub.name : ""}
                                  subTitle={this.state.selectedCardId == null || this.state.selectedCardId == itemSub.id ? "" : itemSub.name}
                                  style={{
                                      width: widthLastShortcuts + 'em',
                                      height: '6em',
                                      borderLeft: this.getBorderColor()
                                  }}>

                            </Card>
                        </div>
                    );
                }

            });
        }

        return form;


    }

    renderCards() {
        let form = [];
        if (GeneralUtils.isMobile()) {
            form.push(<div style={{
                display: "grid",
                gridAutoFlow: "row",
                gridTemplateColumns: "repeat(2, 1fr)",
                gridTemplateRows: "repeat(2, 1fr)"
            }}>
                {this.renderCard()}
            </div>);
        } else {
            form.push(<div style={{
                display: "grid",
                gridAutoFlow: "row",
                marginTop: "-60px",
                width: "100%",
                gridTemplateColumns: "repeat(3, 1fr)",
                gridTemplateRows: "repeat(1, 1fr)"
            }}>
                {this.renderCard()}
            </div>);
        }


        return form;
    }

    renderCrumbs() {
        const home = {
            icon: 'pi pi-home', id: -1, command: (e) => {
                this.setState({
                    crumbItems: [], products: [],
                    shortcuts: [],
                    selectedCardId: null
                });
                this.getProductsForHomeParent();
                this.getWebMenuList();
            }
        }
        if (this.state.crumbItems.length > 0 && this.state.searchText.length <= 3)
            return (
                <div className="card" style={{border: '0', marginTop: GeneralUtils.isMobile() ? "0px" : "-50px"}}>
                    <BreadCrumb style={{borderLeft: this.getBorderColor()}} model={this.state.crumbItems} home={home}/>
                </div>
            );
    }

    renderItems() {

        let form = [];
        form.push(this.renderCrumbs());
        if (this.state.searchText.length <= 3) {
            form.push(
                <Row>
                    {this.renderCards()}
                </Row>
            );
        }

        let listStyle = {
            height: 'auto', columns: 2
        };
        if (GeneralUtils.isMobile()) {
            listStyle = {
                height: 'auto', columns: 1
            };
        }

        if (this.state.addressInfo.menuDisplayTemplate == "Template2") {
            listStyle = {
                height: 'auto', flexDirection: 'row', flexWrap: "wrap", display: "grid",
                gridTemplateColumns: "repeat(auto-fill,minmax(160px, 1fr))"

            };
            if (GeneralUtils.isMobile()) {
                listStyle = {
                    height: 'auto', flexDirection: 'row', flexWrap: "wrap", display: "flex"
                };

            }
        }


        form.push(
            <div className="orderlist-demo">
                <div className="card" style={{position: "inherit", border: '0px', marginTop: "-20px"}}>
                    <OrderList value={this.state.products}
                               listStyle={listStyle}
                               dataKey="id"
                               itemTemplate={this.productItem}

                    ></OrderList>
                </div>
            </div>
        )


        return form;
    }

}