import React from 'react';
import MerchantBranchFilterColumn from "../../shared/component/dataTable/columns/MerchantBranchFilterColumn.json";
import DataTable from "../../shared/component/dataTable/DataTable";
import {Fieldset} from "primereact/fieldset";
import {Button} from "reactstrap";
import Translate from "../../shared/language/Translate";

export default class MerchantBranchFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.refreshDataTable();
    }

    render() {
        return (
            <Fieldset legend="Merchant Branches">
                <DataTable ref={(elem) => this.refDataTable = elem} fields={MerchantBranchFilterColumn.fields}
                           objectName={MerchantBranchFilterColumn.name}
                           dataURL="crm/merchant/branch/all"
                           rows={10}
                           closeFilterDialog={this.props.closeDisplayDialog}
                           onDataSelect={this.onDataSelect}
                           selectionMode="single"
                           loading={this.state.loading}/>


                <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}} onClick={() => {
                    this.props.closeDisplayDialog();

                }}>
                    <Translate contentKey="entity.action.cancel">Close</Translate>
                </Button>
            </Fieldset>
        );
    }

    refreshDataTable = () => {
        this.refDataTable.refresh();
    }
    onDataSelect = (data) => {
        this.props.onDataSelect(data);
    }


}
