import React from 'react';
import MerchantAccountColumn from "../../shared/component/dataTable/columns/MerchantAccountColumn.json";
import {Button, Row} from 'reactstrap';
import Translate from "../../shared/language/Translate";
import DataTablePaginationWithButton from "../../shared/component/dataTable/DataTablePaginationWithButton";
import {Fieldset} from "primereact/fieldset";
import LicenseService from "../../shared/service/LicenseService";
import GeneralUtils from "../../shared/utils/GeneralUtils";

export default class MerchantAccountList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selected: {},
            accountList:[]
        };
        this.licenseService = new LicenseService();
    }

    componentDidMount() {
        this.refreshDataTable();
    }

    render() {
        return (
            <Fieldset legend="Merchant Accounts">
                <Button color="outline-primary" style={{marginBottom:'5px'}} onClick={() => {
                    window.location.href = "#merchantAccountSave?"
                }}>
                    <Translate contentKey="entity.action.add">Add</Translate>
                </Button>

                <DataTablePaginationWithButton ref={(elem) => this.refDataTable = elem} fields={MerchantAccountColumn.fields}
                                     objectName={MerchantAccountColumn.name}
                                     dataURL="crm/merchant/account/all"
                                     view={this.view}
                                     rows={10}
                                     user={this.user}
                                     selectionMode="single"
                                     loading={this.state.loading}/>
            </Fieldset>
        );
    }
    user = (data) => {
        localStorage.setItem("merchantAccountId",data.id);
        window.location.href = "#merchantUserSave?" + data.id;
    }

    view = (data) => {
        window.location.href = "#merchantAccountSave?" + data.id;
    }
    refresh = () => {
        this.refreshDataTable();
    }

    refreshDataTable = () => {
        this.refDataTable.refresh();
    }



}
