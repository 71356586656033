import moment from "moment";

export const convertDateTimeFromServer = date => (date ? moment(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateTimeWithFormat = (date, format) => (date ? moment(date).format(format) : null);

//!!!!!! index.html dont' forget
//LOCALE
//
 //export const SERVER_URL = 'http://admin.dispatchio.co.uk:8762/';

//PROD
//export const SERVER_URL = 'https://admin.dispatchio.store/service/';

//TEST
export const SERVER_URL = 'https://admin.posmarket.site/service/';



export const WEB_IMAGE_SERVER_URL = SERVER_URL.replace("service/","images/products/");


export const APP_DATE_FORMAT = 'DD/MM/YYYY HH:mm';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDThh:mm';
export const AUTHORIZATION_HEADER = 'Authorization';


export const TILL_IMAGE = "TILL";
export const WEB_MENU_SMALL_IMAGE = "WEB_MENU_SMALL";

export const LANGUAGES = [
    {name: 'English', value: 'en'},
    {name: 'Türkçe', value: 'tr'}
]

export const PAGE_MODE = {
    CREATE: 'create',
    UPDATE: 'update',
    VIEW: 'view'
};

export const COUNTRY = [
    {name: 'United Kingdom', value: 'United Kingdom'}
];

export const SECTOR = [
    {name: 'Retail', value: 'RETAIL'},
    {name: 'Hospitality', value: 'HOSPITALITY'},
];

export const HOME_PAGE = [
    {name: 'Orders', value: '#orders'}
];

export const BASKET_TYPE = [
    {name: 'Sale', value: 'sale'},
    {name: 'Refund', value: 'refund'}
];


export const CREDIT_CARD_TYPE = [
    {name: 'MASTER CARD', value: 'MASTER_CARD'},
    {name: 'VISA', value: 'VISA'}
];

export const MONTH = [
    {name: 'January', value: 1},
    {name: 'February', value: 2},
    {name: 'March', value: 3},
    {name: 'April', value: 4},
    {name: 'May', value: 5},
    {name: 'June', value: 6},
    {name: 'July', value: 7},
    {name: 'August', value: 8},
    {name: 'September', value: 9},
    {name: 'October', value: 10},
    {name: 'November', value: 11},
    {name: 'December', value: 12},
];

export const ORDER_TYPE = [
    {name: 'EAT IN', value: 'EAT_IN'},
    {name: 'DELIVERY', value: 'DELIVERY'},
    {name: 'COLLECTION', value: 'COLLECTION'},
    {name: 'TAKEAWAY', value: 'TAKEAWAY'}

];

export const ERROR_TYPE = [
    {name: '', value: 'INIT'},
    {name: 'PRINTER', value: 'PRINTER'},
    {name: 'TABLE NOT FOUND', value: 'TABLE_NOT_FOUND'}
];

export const PERSON_TITLE = [
    {name: 'MR', value: 'MR'},
    {name: 'MRS', value: 'MRS'},
    {name: 'MISS', value: 'MISS'}
];

export const MERCHANT_USER_GROUPS = [
    {name: 'MERCHANT BRANCH ADMIN', value: 'MERCHANT_BRANCH_ADMIN'},
    {name: 'MERCHANT BRANCH USER', value: 'MERCHANT_BRANCH_USER'},
];

export const SERVICE_CHARGE_OPTIONS = [
    {name: 'PASSIVE', value: 'PASSIVE'},
    {name: 'ACTIVE', value: 'ACTIVE'},
    {name: 'ENFORCED', value: 'ENFORCED'}
];

export const MENU_DISPLAY_TYPE = [
    {name: 'By Name', value: 'e.name'},
    {name: 'By Insertion', value: 'e.createDate'},
    {name: 'By Sort No', value: 'e.menuDisplayNo, e.name'}
];

export const MENU_DISPLAY_TEMPLATE = [
    {name: 'Template 1', value: 'Template1'},
    {name: 'Template 2', value: 'Template2'}
];

export const SERVICE_CHARGE_DEFAULT_VALUE = [
    {name: '5%', value: '5%'},
    {name: '10%', value: '10%'},
    {name: '20%', value: '20%'},
];


export const ORDER_STATUS = [
    {name: 'CREATED', value: 'ORDER_CREATED'},
    {name: 'CANCELLED', value: 'ORDER_CANCELLED'},
    {name: 'PLACED', value: 'ORDER_PLACED'},
    {name: 'ACCEPTED', value: 'ORDER_ACCEPTED'},
    {name: 'DECLINED', value: 'ORDER_DECLINED'},
    {name: 'PREPARING', value: 'ORDER_PREPARING'},
    {name: 'READY', value: 'ORDER_READY'},
    {name: 'DISPATCHED', value: 'ORDER_DISPATCHED'},
    {name: 'DELIVERED', value: 'ORDER_DELIVERED'},
    {name: 'CLOSED', value: 'ORDER_CLOSED'}
];

export const BASKET_STATUS = [
    {name: 'IN PROGRESS', value: 'INPROGRESS'},
    {name: 'CANCELLED', value: 'CANCELLED'},
    {name: 'PAID', value: 'PAID'},
    {name: 'PAUSED', value: 'PAUSED'},
    {name: 'PARTIAL PAID', value: 'PARTIAL_PAID'}
];

export const TABLE_ORDER_PAYMENT_OPTIONS = [
    {name: 'CARD', code: 'CARD'},
    {name: 'APPLE PAY', code: 'APPLE PAY'},
    {name: 'PAY PAL', code: 'PAY PAL'},
    {name: 'PAY AT COUNTER', code: 'PAY AT COUNTER'}
];

export const ORDER_NOTIFICATION_TUNNEL_OPTIONS = [
    {name: 'RECEIPT PRINTER', code: 'RECEIPT PRINTER'},
    {name: 'MAIL', code: 'MAIL'}

];

export const WEB_ORDER_PAYMENT_OPTIONS = [
    {name: 'CARD (DELIVERY)', code: 'CARD (DELIVERY)'},
    {name: 'APPLE PAY (DELIVERY)', code: 'APPLE PAY (DELIVERY)'},
    {name: 'PAY PAL (DELIVERY)', code: 'PAY PAL (DELIVERY)'},
    {name: 'PAY AT DOOR BY CARD (DELIVERY)', code: 'PAY AT DOOR BY CARD (DELIVERY)'},
    {name: 'PAY AT DOOR BY CASH (DELIVERY)', code: 'PAY AT DOOR BY CASH (DELIVERY)'},
    {name: 'CARD (COLLECTION)', code: 'CARD (COLLECTION)'},
    {name: 'APPLE PAY (COLLECTION)', code: 'APPLE PAY (COLLECTION)'},
    {name: 'PAY PAL (COLLECTION)', code: 'PAY PAL (COLLECTION)'},
    {name: 'PAY AT COUNTER (COLLECTION)', code: 'PAY AT COUNTER (COLLECTION)'},

];

export const CREDIT_TYPE = [
    {name: 'ADDRESS', value: 'ADDRESS'},
    {name: 'DISTANCE', value: 'DISTANCE'}
];

export const STATUS = [
    {name: 'ACTIVE', value: 'ACTIVE'},
    {name: 'PASSIVE', value: 'PASSIVE'}
];

export const FONT = [
    {name: 'small', value: '2.0vmin'},
    {name: 'small-medium', value: '2.5vmin'},
    {name: 'medium', value: '3.0vmin'},
    {name: 'medium-big', value: '4.0vmin'},
    {name: 'big', value: '5.0vmin'},
    {name: 'very-big', value: '6.5vmin'},
    {name: 'extra-big', value: '8.0vmin'}
];

export const MM_PRINT = [
    {name: 'Print Label', value: 'LABEL'},
    {name: 'Print Product', value: 'PRODUCT'},
    {name: 'Print Both', value: 'BOTH'}
];


export const FONT_WEIGHT = [
    {name: 'normal', value: 'normal'},
    {name: 'bold', value: 'bold'}
];

export const TEXT_ALIGN = [
    {name: 'left', value: 'left'},
    {name: 'center', value: 'center'},
    {name: 'right', value: 'right'},
];

export const BARCODE_TYPE = [
    {name: 'NORMAL', value: 'NORMAL'},
    {name: 'PRICE', value: 'PRICE'},
    {name: 'WEIGHT', value: 'WEIGHT'}
];

export const BARCODE_DIGITS = [
    {name: '3', value: '3'},
    {name: '4', value: '4'},
    {name: '5', value: '5'},
];

export const BARCODE_LENGTH = [
    {name: '6', value: '6'},
    {name: '7', value: '7'},
    {name: '8', value: '8'},
    {name: '9', value: '9'}
];

export const PAYMENT_INTEGRATION_PROVIDER = [
    {name: "DOJO", value: "DOJO"},
    {name: "OPAYO", value: "OPAYO"}
];

export const SELECTION_TYPE = [
    {name: 'SINGLE', value: 'SINGLE'},
    {name: 'MULTIPLE', value: 'MULTIPLE'}
];

export const PRICING_TYPE = [
    {name: 'PRODUCT PRICE', value: 'PRODUCT_PRICE'},
    {name: 'SET PRICE', value: 'SET_PRICE'}
];

export const END_OF_DAY_TIME = [
    {name: '0:00', value: '00'},
    {name: '1:00', value: '01'},
    {name: '2:00', value: '02'},
    {name: '3:00', value: '03'},
    {name: '4:00', value: '04'},
    {name: '5:00', value: '05'},
    {name: '6:00', value: '06'},
    {name: '7:00', value: '07'},
    {name: '8:00', value: '08'},
    {name: '9:00', value: '09'},
    {name: '10:00', value: '10'},
    {name: '11:00', value: '11'},
    {name: '12:00', value: '12'},
    {name: '13:00', value: '13'},
    {name: '14:00', value: '14'},
    {name: '15:00', value: '15'},
    {name: '16:00', value: '16'},
    {name: '17:00', value: '17'},
    {name: '18:00', value: '18'},
    {name: '19:00', value: '19'},
    {name: '20:00', value: '20'},
    {name: '21:00', value: '21'},
    {name: '22:00', value: '22'},
    {name: '23:00', value: '23'}
];

export const HOUR_AND_MINUTE = [
    {name: '0:00', value: '00:00'},
    {name: '0:30', value: '00:30'},
    {name: '1:00', value: '01:00'},
    {name: '1:30', value: '01:30'},
    {name: '2:00', value: '02:00'},
    {name: '2:30', value: '02:30'},
    {name: '3:00', value: '03:00'},
    {name: '3:30', value: '03:30'},
    {name: '4:00', value: '04:00'},
    {name: '4:30', value: '04:30'},
    {name: '5:00', value: '05:00'},
    {name: '5:30', value: '05:30'},
    {name: '6:00', value: '06:00'},
    {name: '6:30', value: '06:30'},
    {name: '7:00', value: '07:00'},
    {name: '7:30', value: '07:30'},
    {name: '8:00', value: '08:00'},
    {name: '8:30', value: '08:30'},
    {name: '9:00', value: '09:00'},
    {name: '9:30', value: '09:30'},
    {name: '10:00', value: '10:00'},
    {name: '10:30', value: '10:30'},
    {name: '11:00', value: '11:00'},
    {name: '11:30', value: '11:30'},
    {name: '12:00', value: '12:00'},
    {name: '12:30', value: '12:30'},
    {name: '13:00', value: '13:00'},
    {name: '13:30', value: '13:30'},
    {name: '14:00', value: '14:00'},
    {name: '14:30', value: '14:30'},
    {name: '15:00', value: '15:00'},
    {name: '15:30', value: '15:30'},
    {name: '16:00', value: '16:00'},
    {name: '16:30', value: '16:30'},
    {name: '17:00', value: '17:00'},
    {name: '17:30', value: '17:30'},
    {name: '18:00', value: '18:00'},
    {name: '18:30', value: '18:30'},
    {name: '19:00', value: '19:00'},
    {name: '19:30', value: '19:30'},
    {name: '20:00', value: '20:00'},
    {name: '20:30', value: '20:30'},
    {name: '21:00', value: '21:00'},
    {name: '21:30', value: '21:30'},
    {name: '22:00', value: '22:00'},
    {name: '22:30', value: '22:30'},
    {name: '23:00', value: '23:00'},
    {name: '23:30', value: '23:30'}
];

export const PRODUCT_UNIT = [
    {name: 'each', value: 'each'},
    {name: 'kg', value: 'kg'},
    {name: 'gr', value: 'gr'},
    {name: 'mg', value: 'mg'},
    {name: 'lt', value: 'lt'},
    {name: 'cl', value: 'cl'},
    {name: 'ml', value: 'ml'},
    {name: 'pint', value: 'pint'},
    {name: 'metre', value: 'metre'},
    {name: 'cm', value: 'cm'},
    {name: 'mm', value: 'mm'},
    {name: 'sgl', value: 'sgl'},
    {name: 'dbl', value: 'dbl'},
    {name: 'piece', value: 'piece'},
    {name: 'pack', value: 'pack'},
    {name: 'oz', value: 'oz'},
    {name: 'half_pint', value: 'half_pint'},
    {name: 'inch', value: 'inch'},
    {name: 'size', value: 'size'}
];

export const PRODUCT_TYPE = [
    {name: 'PRODUCT_LINE', value: 'PRODUCT_LINE'},
    {name: 'MESSAGE_LINE', value: 'MESSAGE_LINE'},
    {name: 'DISCOUNT_LINE', value: 'DISCOUNT_LINE'},
    {name: 'SERVICE_LINE', value: 'SERVICE_LINE'},
    {name: 'MISC', value: 'MISC'}
];

export const STOCK_EVENT = [
    {name: 'Add Stock', value: 'ADD_STOCK'},
    {name: 'Drop Stock', value: 'DROP_STOCK'},
];

export const STOCK_EVENT_ADD_REASON = [
    {name: 'Delivery', value: 'DELIVERY'},
    {name: 'Other', value: 'OTHER_ADD'},
];

export const STOCK_EVENT_DROP_REASON = [
    {name: 'Expired', value: 'EXPIRED'},
    {name: 'Theft', value: 'THEFT'},
    {name: 'Damaged', value: 'DAMAGED'},
    {name: 'Lost', value: 'LOST'},
    {name: 'Other', value: 'OTHER_DROP'},
];

export const YESNO = [
    {name: 'No', value: false},
    {name: 'Yes', value: true},
];

export const OPENCLOSE = [
    {name: 'Close', value: false},
    {name: 'Open', value: true},
];

export const LICENSE_STATUS = [
    {name: 'DEMO', value: 'DEMO'},
    {name: 'ACTIVE', value: 'ACTIVE'},
    {name: 'PASSIVE', value: 'PASSIVE'},
    {name: 'CLIENT DEACTIVATED', value: 'CLIENT_DEACTIVATED'}
];

export const SERVICE_NAME = [
    {name: 'TABLE ORDER', value: 'TABLE_ORDER'},
    {name: 'WEB ORDER', value: 'WEB_ORDER'},
    {name: 'WEB MENU', value: 'WEB_MENU'},
    {name: 'ORDER DISPLAY', value: 'ORDER_DISPLAY'},
    {name: 'WEB BACK OFFICE', value: 'WEB_BACK_OFFICE'},
    {name: 'PRINTSEL', value: 'PRINTSEL'},
    {name: 'ARTOFFICE', value: 'ARTOFFICE'},
    {name: 'ARTPOS', value: 'ARTPOS'}
];


export const ACTION_REASON = [
    {name: 'EXPIRED', value: 'EXPIRED'},
    {name: 'PAYMENT REQUIRED', value: 'PAYMENT_REQUIRED'},
    {name: 'FRAUD', value: 'FRAUD'},
    {name: 'LICENCE CHECK FAILED', value: 'LICENCE_CHECK_FAILED'},
    {name: 'SERVICE REGISTERED', value: 'SERVICE_REGISTERED'},
    {name: 'SERVICE UNREGISTERED', value: 'SERVICE_UNREGISTERED'},
    {name: 'SERVICE REACTIVATED', value: 'SERVICE_REACTIVATED'},

];


export const MIXMATCH_TYPE = [
    {name: 'MIXMATCH', value: 'MIXMATCH'},
    {name: 'LOYALTY', value: 'LOYALTY'},
    {name: 'SERVICE', value: 'SERVICE'},
    {name: 'LUCKY DAY', value: 'LUCKY_DAY'}

];

export const MIXMATCH_LEVEL = [
    {name: 'BASKET', value: 'BASKET'},
    {name: 'PRODUCT', value: 'PRODUCT'},
    {name: 'BASKET LIMITED', value: 'BASKET_LIMITED'},
    {name: 'DEPARTMENT', value: 'DEPARTMENT'}
];

export const MIXMATCH_OPERATION = [
    {name: 'DISCOUNT PERCENT', value: 'DISCOUNT_PERCENT'},
    {name: 'DISCOUNT AMOUNT', value: 'DISCOUNT_AMOUNT'},
    {name: 'FIXED PRICE', value: 'FIXED_PRICE'},
    {name: 'PRODUCT', value: 'PRODUCT'}
];



export const LAST_NDAYS = [
    {name: 'Last Week', value: 7},
    {name: 'Last Month', value: 30},
    {name: 'Last Three Months', value: 90}
];

export const DATA_UPLOAD_ENTITY = [
    {name: 'Tax', id: 0},
    {name: 'Department', id: 1},
    {name: 'Product Group', id: 2},
    {name: 'Product', id: 3},
    {name: 'Web Menu', id: 4},
    {name: 'Mix & Match', id: 5},
    {name: 'Floor Planning', id: 6},
    {name: 'Tag', id: 7},
    {name: 'Product Tag', id: 8},
    {name: 'Supplier', id: 9},
    {name: 'Till Customer', id: 10},
];
export const PRICE_CALCULATION_METHOD = [
    {name: '', value: 'EMPTY'},
    {name: 'NO_ROUND', value: 'NO_ROUND'},
    {name: 'RATE_ROUND_UP', value: 'RATE_ROUND_UP'},
    {name: 'RATE_ROUND_UP_99', value: 'RATE_ROUND_UP_99'},
    {name: 'RATE_ROUND_DOWN', value: 'RATE_ROUND_DOWN'},
    {name: 'RATE_ROUND_DOWN_99', value: 'RATE_ROUND_DOWN_99'},
    {name: 'RATE_ROUND_HALF_49', value: 'RATE_ROUND_HALF_49'},
    {name: 'RATE_ROUND_HALF_49_99', value: 'RATE_ROUND_HALF_49_99'},

];

export const FEEDBACK_TYPE = [
    {name: 'Technical', value: 'Technical'},
    {name: 'Other', value: 'Other'}
];





