import React from 'react';
import {Col, Label, Row} from 'reactstrap';
import {AvFeedback, AvGroup} from 'availity-reactstrap-validation';
import {Button} from 'reactstrap';
import PropTypes from 'prop-types';
import GeneralUtils from "../../shared/utils/GeneralUtils";
import AvField from "../../shared/component/form/AvField";
import Translate from "../../shared/language/Translate";
import {ACTION_REASON, LICENSE_STATUS, SERVICE_NAME} from "../../shared/utils/Constants";
import {Fieldset} from "primereact/fieldset";
import DateInput from "../../shared/component/form/DateInput";
import LicenseService from "../../shared/service/LicenseService";
import {InputNumber} from "primereact/inputnumber";
import {toast} from "react-toastify";
import ModalConfirm from "../../shared/component/dialog/ModalConfirm";
import DataTableAll from "../../shared/component/dataTable/DataTableAll";
import KeyAndValueColumn from "../../shared/component/dataTable/columns/KeyAndValueColumn.json";
import {Checkbox} from "primereact/checkbox";

const defaultLicense = {
    id: null,
    licenseStatus: 'ACTIVE',
    actionReason: 'SERVICE_REGISTERED',
    actionReasonDescription: '',
    serviceName: '',
    validDays: 0,
    clientCreatedDate: null,
    updateDate: null,
    remainingDays: null,
    skipCheck: false,
    resetClient: false,
    till: true,
    icr: false,
    extraData: {},
    bssId: '',
    updatePeriod: '0 0 */6 * * *',
    ipAddress: '',
    appVersion: '',
    noOfClients: 1,
    licenceCode: '',
    clientCodes: '',
    hardwareSerial: '',
    issueDate: null
};

const defaultExtraData = {
    key: '',
    value: ''
}
export default class AppLicenseSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            license: Object.assign({}, defaultLicense),
            licenseTemp: Object.assign({}, defaultLicense),
            extraData: Object.assign({}, defaultExtraData),
            renderExtraDataDeleteButton: false,
            pageMode: null,
            credentialList: []
        }
        this.licenseService = new LicenseService();
    }


    renderLicenses() {
        return (
            <div>


                <Fieldset legend={"License"}>
                    <ModalConfirm header='Warning' ok='OK' onOkClick={this.delete}
                                  onCancelClick={() => this.setState({displayShowStatusConfirmDialog: false})}
                                  show={this.state.displayShowStatusConfirmDialog}
                                  message={this.state.noticeResponse}/>
                    <Row>
                        <Col md="6">
                            <AvGroup>
                                <Label for="serviceName">
                                    <Translate contentKey="merchantBranch.serviceName">Service
                                        Name</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="select"
                                    className="form-control"
                                    name="serviceName"
                                    disabled={!this.state.licenseSaveButtonRender || !GeneralUtils.isNullOrEmpty(this.state.license.id)}
                                    value={this.state.license.serviceName}
                                    onChange={this.__handleChange}>
                                    <option value='' key=''/>
                                    {SERVICE_NAME.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                        </Col>
                        <Col md="6">
                            <AvGroup>
                                <Label for="licenseStatus">
                                    <Translate contentKey="merchantBranch.licenseStatus">License
                                        Status</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="select"
                                    className="form-control"
                                    name="licenseStatus"
                                    disabled={!this.state.licenseSaveButtonRender}
                                    value={this.state.license.licenseStatus}
                                    onChange={this.__handleChangeStatus}>
                                    <option value='' key=''/>
                                    {LICENSE_STATUS.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <AvGroup>
                                <Label for="actionReason">
                                    <Translate contentKey="merchantBranch.actionReason">Action
                                        Reason</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="select"
                                    className="form-control"
                                    name="actionReason"
                                    disabled={!this.state.licenseSaveButtonRender}
                                    value={this.state.license.actionReason}
                                    onChange={this.__handleChange}>
                                    <option value='' key=''/>
                                    {ACTION_REASON.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                        </Col>

                        <Col md="6">
                            <AvGroup>
                                <Label for="actionReasonDescription">
                                    <Translate
                                        contentKey="merchantBranch.actionReasonDescription">Description</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="actionReasonDescription"
                                    disabled={!this.state.licenseSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 255 characters in this field."
                                        }
                                    }}
                                    value={this.state.license.actionReasonDescription}
                                    onChange={this.__handleChange}/>
                            </AvGroup>


                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            <AvGroup>
                                <Label for="validDays">
                                    <Translate
                                        contentKey="merchantBranch.validDays">Valid Days</Translate>
                                </Label>

                                <InputNumber value={this.state.license.validDays}
                                             disabled={!this.state.licenseSaveButtonRender}
                                             name="validDays"
                                             min={0} max={100}
                                             className="input-group"
                                             onChange={this.__handleChange}
                                             showButtons/>


                            </AvGroup>
                        </Col>
                        <Col md="6">
                            <AvGroup>
                                <Label for="remainingDaysText">
                                    <Translate contentKey="merchantBranch.remainingDaysText">Remaining Days
                                    </Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="remainingDaysText"
                                    disabled={true}
                                    value={this.state.license.remainingDaysText}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                        </Col>
                    </Row>


                    <Row>
                        <Col md="6">
                            <AvGroup>
                                <Label for="createDate">
                                    <Translate
                                        contentKey="merchantBranch.createDate">Create Date</Translate>
                                </Label>

                                <DateInput
                                    name="clientCreatedDate"
                                    onChange={this.__handleChange}
                                    disabled={true}
                                    format={"DD/MM/YYYY"}
                                    value={this.state.license.clientCreatedDate}/>

                            </AvGroup>

                        </Col>

                        <Col md="6">
                            <AvGroup>
                                <Label for="updateDate">
                                    <Translate
                                        contentKey="merchantBranch.updateDate">Update Date</Translate>
                                </Label>

                                <DateInput
                                    name="updateDate"
                                    format={"DD/MM/YYYY"}
                                    onChange={this.__handleChange}
                                    disabled={true}
                                    value={this.state.license.updateDate}/>

                            </AvGroup>

                        </Col>
                    </Row>


                    {(this.state.license.serviceName == "ARTPOS" || this.state.license.serviceName == "PRINTSEL" || this.state.license.serviceName == "ARTOFFICE") && this.renderKeyAndValueList()}


                    {!this.state.licenseSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}} onClick={() => {
                            this.setState({licenseSaveButtonRender: true});
                        }}>
                            <Translate contentKey="entity.action.add">Edit</Translate>
                        </Button>}
                    {this.state.licenseSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}} onClick={() => {
                            if (GeneralUtils.isNullOrEmpty(this.state.license.serviceName) || GeneralUtils.isNullOrEmpty(this.state.license.licenseStatus) || GeneralUtils.isNullOrEmpty(this.state.license.actionReason)
                            ) {
                                return toast.warn("Please fill the required fields!");
                            } else {
                                let data = this.state.license;
                                data["updateDate"] = new Date();
                                this.props.saveAppLicense(data);
                            }

                        }}>
                            <Translate contentKey="entity.action.add">Save</Translate>
                        </Button>
                    }

                    {this.state.licenseSaveButtonRender == false && !GeneralUtils.isNullOrEmpty(this.state.license.id) &&

                        <Button color="outline-danger" style={{marginRight: '5px'}} onClick={() => {
                            this.showChangeStatusConfirmDialog(true)
                        }}>
                            <Translate contentKey="entity.action.add">Delete</Translate>
                        </Button>}


                </Fieldset>

                <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}} onClick={() => {
                    this.setState({
                        licenseSaveButtonRender: false
                    });
                    this.props.closeDisplayDialog();

                }}>
                    <Translate contentKey="entity.action.cancel">Close</Translate>
                </Button>
            </div>
        )
    };


    render() {
        return (
            <div class="p-col-12">
                {this.renderLicenses()}
            </div>
        );
    }

    delete = () => {
        if (!GeneralUtils.isNullOrEmpty(this.state.license.id)) {
            this.props.deleteAppLicense(this.state.license.id);
        }

        return undefined;
    }

    setChecked = (name, value) => {
        let license = this.state.license;
        license[name] = value;
        this.setState({license: license});
    }

    renderKeyAndValueList() {
        const extraDataArray = Object.entries(this.state.license.extraData).map(
            ([key, value]) => ({
                key,
                value,
            })
        );

        return (

            <div>

                <Fieldset legend={"Artpos"}>
                    <Row>
                        <Col md="3">
                            <AvGroup>
                                <Label for="skipCheck">
                                    <Translate contentKey="skipCheck">Skip Control&nbsp;</Translate>
                                </Label>

                                <Checkbox onChange={(e) => this.setChecked("skipCheck", e.checked)}
                                          name="skipCheck"
                                          disabled={!this.state.licenseSaveButtonRender}
                                          value={this.state.license.skipCheck}
                                          checked={this.state.license.skipCheck}></Checkbox>


                            </AvGroup>
                        </Col>

                        <Col md="3">
                            <AvGroup>
                                <Label for="resetClient">
                                    <Translate contentKey="resetClient">Reset Clients&nbsp;</Translate>
                                </Label>

                                <Checkbox onChange={(e) => this.setChecked("resetClient", e.checked)}
                                          name="resetClient"
                                          disabled={!this.state.licenseSaveButtonRender}
                                          value={this.state.license.resetClient}
                                          checked={this.state.license.resetClient}></Checkbox>


                            </AvGroup>
                        </Col>

                        <Col md="3">
                            <AvGroup>
                                <Label for="till">
                                    <Translate contentKey="till">Till&nbsp;</Translate>
                                </Label>

                                <Checkbox onChange={(e) => this.setChecked("till", e.checked)}
                                          name="till"
                                          disabled={!this.state.licenseSaveButtonRender}
                                          value={this.state.license.till}
                                          checked={this.state.license.till}></Checkbox>


                            </AvGroup>
                        </Col>

                        <Col md="3">
                            <AvGroup>
                                <Label for="icr">
                                    <Translate contentKey="icr">Icr&nbsp;</Translate>
                                </Label>

                                <Checkbox onChange={(e) => this.setChecked("icr", e.checked)}
                                          name="icr"
                                          disabled={!this.state.licenseSaveButtonRender}
                                          value={this.state.license.icr}
                                          checked={this.state.license.icr}></Checkbox>


                            </AvGroup>
                        </Col>
                    </Row>

                    <Row>

                        <Col md="6">
                            <AvGroup>
                                <Label for="bssId">
                                    <Translate
                                        contentKey="merchantBranch.bssId">Reg No</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="bssId"
                                    disabled={true}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 255 characters in this field."
                                        }
                                    }}
                                    value={this.state.license.bssId}
                                    onChange={this.__handleChange}/>
                            </AvGroup>


                        </Col>

                        <Col md="6">
                            <AvGroup>
                                <Label for="hardwareSerial">
                                    <Translate
                                        contentKey="merchantBranch.hardwareSerial">Serial</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="hardwareSerial"
                                    disabled={true}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 255 characters in this field."
                                        }
                                    }}
                                    value={this.state.license.hardwareSerial}
                                    onChange={this.__handleChange}/>
                            </AvGroup>


                        </Col>
                    </Row>

                    <Row>

                        <Col md="6">
                            <AvGroup>
                                <Label for="ipAddress">
                                    <Translate
                                        contentKey="merchantBranch.ipAddress">Ip</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="ipAddress"
                                    disabled={true}
                                    value={this.state.license.ipAddress}
                                    onChange={this.__handleChange}/>
                            </AvGroup>


                        </Col>


                        <Col md="6">
                            <AvGroup>
                                <Label for="updatePeriod">
                                    <Translate
                                        contentKey="merchantBranch.updatePeriod">Update Period</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="updatePeriod"
                                    disabled={!this.state.licenseSaveButtonRender}
                                    value={this.state.license.updatePeriod}
                                    onChange={this.__handleChange}/>
                            </AvGroup>


                        </Col>


                    </Row>

                    <Row>

                        <Col md="6">
                            <AvGroup>
                                <Label for="appVersion">
                                    <Translate
                                        contentKey="merchantBranch.appVersion">Version</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="appVersion"
                                    disabled={true}
                                    value={this.state.license.appVersion}
                                    onChange={this.__handleChange}/>
                            </AvGroup>


                        </Col>


                        <Col md="6">
                            <AvGroup>
                                <Label for="noOfClients">
                                    <Translate
                                        contentKey="merchantBranch.noOfClients">Clients</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="noOfClients"
                                    disabled={!this.state.licenseSaveButtonRender}
                                    value={this.state.license.noOfClients}
                                    onChange={this.__handleChange}/>
                            </AvGroup>


                        </Col>


                    </Row>

                    <Row>

                        <Col md="6">
                            <AvGroup>
                                <Label for="licenceCode">
                                    <Translate
                                        contentKey="merchantBranch.licenceCode">Licence Number</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="licenceCode"
                                    disabled={true}
                                    value={this.state.license.licenceCode}
                                    onChange={this.__handleChange}/>
                            </AvGroup>


                        </Col>


                        <Col md="6">
                            <AvGroup>
                                <Label for="issueDate">
                                    <Translate
                                        contentKey="merchantBranch.issueDate">Issue Date</Translate>
                                </Label>

                                <DateInput
                                    name="issueDate"
                                    format={"DD/MM/YYYY"}
                                    onChange={this.__handleChange}
                                    disabled={true}
                                    value={this.state.license.issueDate}/>

                            </AvGroup>

                        </Col>


                    </Row>
                    <Row>
                        <Col md="6">
                            <AvGroup>
                                <Label for="noOfClients">
                                    <Translate
                                        contentKey="merchantBranch.noOfClients">Client Ids</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="clientCodes"
                                    disabled={!this.state.licenseSaveButtonRender}
                                    value={this.state.license.clientCodes}
                                    onChange={this.__handleChange}/>
                            </AvGroup>


                        </Col>

                    </Row>

                    <Row>
                        <Col md="6">
                            <AvGroup>
                                <Label for="activationCode">
                                    <Translate
                                        contentKey="merchantBranch.activationCode">Activation Code</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="activationCode"
                                    disabled={true}
                                    value={this.state.license.activationCode}
                                    onChange={this.__handleChange}/>
                            </AvGroup>
                        </Col>
                        <Col md="4">
                            { !GeneralUtils.isNullOrEmpty(this.state.license.id) &&
                                <Button color="outline-primary" style={{marginRight: '5px', height: '40px'}}
                                        onClick={() => {

                                            this.licenseService.generateActivationCode(this.state.license.id)
                                                .then((response) => {
                                                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {

                                                        this.setState({
                                                            license: response,
                                                            licenseTemp: Object.assign({}, response),
                                                            licenseSaveButtonRender: false
                                                        })

                                                    }
                                                });

                                        }}>
                                    <Translate contentKey="entity.action.add">Generate Code</Translate>
                                </Button>
                            }

                        </Col>


                    </Row>


                    <Fieldset legend={"Extra Data"}>
                        <Row>
                            <Col md="4">
                                <AvGroup>
                                    <Label for="key">
                                        <Translate contentKey="merchantBranch.key">Key
                                        </Translate>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="key"
                                        disabled={!this.state.licenseSaveButtonRender}
                                        value={this.state.extraData.key}
                                        onChange={this.__handleChangeExtraData}/>
                                </AvGroup>
                            </Col>
                            <Col md="4">
                                <AvGroup>
                                    <Label for="value">
                                        <Translate contentKey="merchantBranch.value">Value
                                        </Translate>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="value"
                                        disabled={!this.state.licenseSaveButtonRender}
                                        value={this.state.extraData.value}
                                        onChange={this.__handleChangeExtraData}/>
                                </AvGroup>
                            </Col>

                            <Col md="4">
                                <Button disabled={!this.state.licenseSaveButtonRender} color="outline-primary"
                                        style={{marginRight: '5px', height: '30px'}} onClick={() => {

                                    if (GeneralUtils.isNullOrEmpty(this.state.extraData.key) || GeneralUtils.isNullOrEmpty(this.state.extraData.value)
                                    ) {
                                        return toast.warn("Please fill key and value");
                                    } else {
                                        let data = {
                                            key: this.state.extraData.key,
                                            value: this.state.extraData.value
                                        };
                                        let license = this.state.license;
                                        if (license.extraData.hasOwnProperty(data.key)) {
                                            license.extraData[data.key] = data.value;
                                            this.setState({
                                                renderExtraDataDeleteButton: false,
                                                license: license,
                                                extraData: Object.assign({}, defaultExtraData)
                                            });
                                        } else {
                                            license.extraData[data.key] = data.value;
                                            this.setState({
                                                renderExtraDataDeleteButton: false,
                                                license: license,
                                                extraData: Object.assign({}, defaultExtraData)
                                            });
                                        }

                                    }

                                }}>
                                    <Translate contentKey="entity.action.cancel">Save</Translate>
                                </Button>
                                {this.state.renderExtraDataDeleteButton &&
                                    <Button color="outline-primary" disabled={!this.state.licenseSaveButtonRender}
                                            style={{marginRight: '5px', height: '30px', marginTop: '5px'}}
                                            onClick={() => {

                                                if (GeneralUtils.isNullOrEmpty(this.state.extraData.key)
                                                ) {
                                                    return toast.warn("Please fill key");
                                                } else {
                                                    let data = {
                                                        key: this.state.extraData.key,
                                                        value: this.state.extraData.value
                                                    };
                                                    let license = this.state.license;
                                                    delete license.extraData[data.key];
                                                    this.setState({
                                                        license: license,
                                                        extraData: Object.assign({}, defaultExtraData)
                                                    });

                                                }

                                            }}>
                                        <Translate contentKey="entity.action.cancel">Delete</Translate>
                                    </Button>
                                }
                            </Col>


                        </Row>


                        <DataTableAll
                            fields={KeyAndValueColumn.fields}
                            objectName={KeyAndValueColumn.name}
                            data={extraDataArray}
                            rows={5}
                            selectionMode="single" onDataSelect={this.onDataSelect}
                        />


                    </Fieldset>


                </Fieldset>
            </div>
        );
    }

    onDataSelect = (data) => {
        let extraData = this.state.extraData;
        extraData["key"] = data.key;
        extraData["value"] = data.value;
        this.setState({extraData: extraData, renderExtraDataDeleteButton: true});
    }

    licenseSaveButtonRender = () => {
        this.setState({licenseSaveButtonRender: true});
    }

    setData = (data) => {
        this.setState({
            license: data,
            licenseTemp: Object.assign({}, data),
            licenseSaveButtonRender: false,
            renderExtraDataDeleteButton: false
        });
    }

    showChangeStatusConfirmDialog = (value) => {
        this.setState({
            displayShowStatusConfirmDialog: true,
            noticeResponse: "Are you sure to delete?"
        });
    }

    clearData = () => {
        let obj = Object.assign({}, defaultLicense);
        this.setState({license: obj, renderExtraDataDeleteButton: false});
    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let license = this.state.license;
        license[e.target.name] = value;
        if (e.target.name == "serviceName" && value != "ARTPOS" && value != "PRINTSEL" ||value != "ARTOFFICE") {
            license['extraData'] = {};
        }
        this.setState({license: license});
    }

    __handleChangeExtraData = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let extraData = this.state.extraData;
        extraData[e.target.name] = value;
        this.setState({extraData: extraData});
    }

    __handleChangeStatus = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let license = this.state.license;
        if (value == 'DEMO') {
            license['validDays'] = '30';
        } else {
            license['validDays'] = '0';
        }
        license[e.target.name] = value;
        this.setState({license: license});
    }
}

AppLicenseSave.propTypes =
    {
        license: PropTypes.object
    }
;
