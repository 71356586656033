import React from 'react';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import Loading from "../../shared/component/others/Loading";
import AdminSaleReportColumn from "../../shared/component/dataTable/columns/AdminSaleReportColumn.json";
import Dialog from "../../shared/component/dialog/Dialog";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import AdminZXReportService from "../../shared/service/AdminZXReportService";
import DataTable from "../../shared/component/dataTable/DataTable";
import AdminSaleReportDetail from "./AdminSaleReportDetail";
import {Button, Col, Label, Row} from "reactstrap";
import Translate from "../../shared/language/Translate";
import {Fieldset} from "primereact/fieldset";
import DateInput from "../../shared/component/form/DateInput";
import {Chart} from 'primereact/chart';
import {InputText} from "primereact/inputtext";
import MerchantBranchFilter from "../merchant/MerchantBranchFilter";


const defaultSummary = {
    netSale: 0,
    grossSale: 0,
    refunds: 0,
    netSaleCnt: 0,
    grossSaleCnt: 0,
    refundsCnt: 0
};

export default class AdminSaleReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            saleReportList: [],
            loading: false,
            displayCalculateConfirmDialog: false,
            noticeResponse: "",
            reportId: null,
            summary: Object.assign(defaultSummary),

        };
        this.adminZXReportService = new AdminZXReportService();

    }

    componentDidMount() {
        this.refreshSummary();

    }


    render() {
        return (<div>
            <AvForm>
                <Fieldset legend="Sale Report">
                    {this.renderFilter()}
                    {this.renderSummaryGraphics()}
                    {this.renderReports()}
                </Fieldset>

            </AvForm>
            {this.state.loading && <Loading/>}
        </div>);
    }

    __handleChangeStartDate = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        this.setState({startDate: value, lastNDays: null});
    }

    __handleChangeLastNDays = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        if (!GeneralUtils.isNullOrEmpty(value)) {
            let startDate = new Date();
            startDate.setDate(startDate.getDate() - value);
            this.setState({endDate: new Date().getTime(), startDate: startDate.getTime()});
        } else {
            this.setState({endDate: null, startDate: null});
        }
        this.setState({lastNDays: value});
    }

    __handleChangeEndDate = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        this.setState({endDate: value, lastNDays: null});
    }


    closeDisplayDialogFilter = () => {
        this.setState({displayDialogFilter: false});
    }

    onDataSelectFilter = (data) => {
        this.setState({merchantBranchId: data.id});
        this.setState({merchantBranchName: data.tradingName});
        this.setState({displayDialogFilter: false});
    }

    renderFilter() {
        return (<div><Row>
            <Col>


            <span className="p-input-icon-right">
                <InputText style={{borderRadius: "10px", width: "240px"}} disabled value={this.state.merchantBranchName}
                           placeholder="Select Merchant Branch"
                           onClick={() => this.setState({displayDialogFilter: true})}/>
                <i
                    className="pi pi-search"
                    style={{cursor: "pointer"}}
                    onClick={() => this.setState({displayDialogFilter: true})}
                />

                <i
                    className="pi pi-times"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                        this.setState({merchantBranchId: null, merchantBranchName: ''})

                    }}
                />



            </span>
            </Col>


            <Col md="2">

                <DateInput
                    name="startDate"
                    format={"DD/MM/YYYY"}
                    placeholder={"From Start Date"}
                    onChange={this.__handleChangeStartDate}
                    disabled={false}
                    value={this.state.startDate}/>


            </Col>

            <Col md="2">

                <DateInput
                    name="endDate"
                    format={"DD/MM/YYYY"}
                    placeholder={"From End Date"}
                    onChange={this.__handleChangeEndDate}
                    disabled={false}
                    value={this.state.endDate}/>


            </Col>


            <Col>
                <Button color="outline-primary" style={{marginBottom: "10px"}}
                        onClick={() => {
                            this.refDataTable.refresh();
                            this.refreshSummary();


                        }}>
                    <Translate contentKey="entity.action.add">Search</Translate>
                </Button>
            </Col>

            <Col>
                <Dialog visible={this.state.displayDialogFilter}
                        header={"Merchant Branch"}
                        onHide={() => this.setState({displayDialogFilter: false})}
                        style={{width: '30vw', height: '35vw'}}>

                    <MerchantBranchFilter
                        ref={(elem) => this.refModelCredentialsSave = elem}
                        onDataSelect={this.onDataSelectFilter}
                        closeDisplayDialog={this.closeDisplayDialogFilter}/>
                </Dialog>
            </Col>

        </Row>

        </div>)
    }

    refreshSummary() {
        let merchantBranchId = -1;
        if (!GeneralUtils.isNullOrEmpty(this.state.merchantBranchId)) {
            merchantBranchId = this.state.merchantBranchId;
        }

        let startDate = -1;
        if (!GeneralUtils.isNullOrEmpty(this.state.startDate)) {
            startDate = this.state.startDate;
        }
        let endDate = -1;
        if (!GeneralUtils.isNullOrEmpty(this.state.endDate)) {
            endDate = this.state.endDate;
        }

        this.adminZXReportService.getSummary(merchantBranchId, startDate, endDate).then(response => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                this.setState({summary: response});
            }

        });

    }

    renderSummaryGraphics() {

        const chartSalesData = {
            labels: ['Net Sale ' + GeneralUtils.formatGBP(this.state.summary.netSale), 'Gross Sale ' + GeneralUtils.formatGBP(this.state.summary.grossSale), 'Refund Sale ' + GeneralUtils.formatGBP(this.state.summary.refunds)],
            datasets: [
                {
                    label: 'Sales Amount (£)',
                    backgroundColor: ['#42A5F5', '#66BB6A', '#FFA726'],
                    data: [this.state.summary.netSale, this.state.summary.grossSale, this.state.summary.refunds],
                }
            ]
        };

        const chartSalesCountData = {
            labels: ['Net Sale ' + this.state.summary.netSaleCnt, 'Gross Sale ' + this.state.summary.grossSaleCnt, 'Refund Sale ' + this.state.summary.refundsCnt],
            datasets: [
                {
                    label: 'Sales Count',
                    backgroundColor: ['#1E88E5', '#43A047', '#FB8C00'],
                    data: [this.state.summary.netSaleCnt, this.state.summary.grossSaleCnt, this.state.summary.refundsCnt]
                }
            ]
        };

        const chartOptions = {
            maintainAspectRatio: false,
            aspectRatio: 1,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        return (
            <div>
                <Fieldset legend={"Summary"}>
                    <Row className="justify-content-center">
                        <div className="d-flex justify-content-center space-x-4">
                            <div className="chart-container">
                                <Chart type="bar" data={chartSalesData} options={chartOptions} height={"180px"}/>
                            </div>
                            <div className="chart-container">
                                <Chart type="bar" data={chartSalesCountData} options={chartOptions} height={"180px"}/>
                            </div>
                        </div>
                    </Row>
                </Fieldset>
            </div>
        );
    }

    renderReports() {
        let merchantBranchId = -1;
        if (!GeneralUtils.isNullOrEmpty(this.state.merchantBranchId)) {
            merchantBranchId = this.state.merchantBranchId;
        }
        let startDate = -1;
        if (!GeneralUtils.isNullOrEmpty(this.state.startDate)) {
            startDate = this.state.startDate;
        }
        let endDate = -1;
        if (!GeneralUtils.isNullOrEmpty(this.state.endDate)) {
            endDate = this.state.endDate;
        }

        return (<div>


            <DataTable fields={AdminSaleReportColumn.fields}
                       ref={(elem) => this.refDataTable = elem}
                       objectName={AdminSaleReportColumn.name}
                       onDataSelect={this.onDataSelect}
                       rows={10}
                       reportName="Sale Reports"
                       paginator={true}
                       dataURL={"reporting/admin/zxreport/merchantBranchId/" + merchantBranchId + "/" + startDate + "/" + endDate}
                       selectionMode="single"
                       loading={this.state.loading}/>


            <Dialog visible={this.state.displayDialog}
                    header={"Sale Report Details"}
                    onHide={() => this.setState({displayDialog: false})}
                    style={GeneralUtils.getDialogStyleForDialog()}>

                <AdminSaleReportDetail closeDisplayDialog={this.closeDisplayDialog}
                                       ref={(elem) => this.refModelSave = elem}
                />
            </Dialog>


        </div>);
    }

    onDataSelect = (data) => {
        this.refModelSave.setData(Object.assign({}, data));
        this.setState({
            displayDialog: true
        });
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
        this.refDataTable.refresh();
        this.refreshSummary();
    }


}

