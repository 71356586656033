import React, {Component} from 'react';
import PropTypes from 'prop-types';
import GeneralUtils from "../../utils/GeneralUtils";
import AdminUserSecurityService from "../../service/AdminUserSecurityService";
import {SERVER_URL} from "../../utils/Constants";


export class AppTopbar extends Component {
    constructor() {
        super();
        this.state = {
            noticeCount: 0
        };
        this.adminUserSecurityService = new AdminUserSecurityService();
    }

    componentDidMount = () => {

        if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("userType")) && localStorage.getItem("userType") == "admin") {
            refreshToken(this.adminUserSecurityService);

            function refreshToken(service) {
                setInterval(function () {
                    if (!GeneralUtils.isNullOrEmpty(GeneralUtils.getRefreshTokenFromCookie())) {
                        service.refreshToken().then(response => {
                            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                GeneralUtils.setJwtToCookie(response.accessToken, response.refreshToken, response.expireDate);
                            }
                        });
                    }

                }, 1000 * 60 * 4);
            }
        }

    }

    render() {
        return (
            <div>
                <div>
                    {
                        GeneralUtils.isTestEnvironment() &&

                        <div id="testEnvironmentDiv"
                             style={{
                                 backgroundColor: "red",
                                 textAlign: "center",
                                 height: "40px",
                                 fontSize: "25px"
                             }}>Test
                            Environment!</div>
                    }
                </div>
                <div className="layout-topbar clearfix">

                    <a className="layout-menu-button" onClick={this.props.onToggleMenu}>
                        <span className="pi pi-bars"/>
                    </a>
                    <div className="layout-topbar-icons">
                        <a onClick={() => {
                            var location = window.location.toString();
                            if (!location.endsWith('#/') ) {
                                if(location.indexOf("merchantAccountSave")>=0 || location.indexOf("merchantBranchSave")>=0){
                                    window.history.go(-2);
                                }
                                else{
                                    window.history.go(-1);
                                }

                            }

                        }}>
                            <span className="layout-topbar-icon pi pi-angle-left"/>
                        </a>
                        <a onClick={() => {
                            window.location = '#/'
                        }}>
                            <span className="layout-topbar-icon pi pi-home"/>
                        </a>
                        <a onClick={this.props.logout}>
                            <span className="layout-topbar-icon pi pi-power-off" style={{marginRight: '180px'}}/>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

AppTopbar.propTypes = {
    onToggleMenu: PropTypes.func.isRequired,
    logout: PropTypes.func
};

AppTopbar.defaultProps = {
    onToggleMenu: null
};
