import React from 'react';
import {Col, Label, Row} from 'reactstrap';
import {AvFeedback, AvGroup} from 'availity-reactstrap-validation';
import {Button} from 'reactstrap';
import GeneralUtils from "../../shared/utils/GeneralUtils";
import AvField from "../../shared/component/form/AvField";
import Translate from "../../shared/language/Translate";
import {STATUS} from "../../shared/utils/Constants";
import {Fieldset} from "primereact/fieldset";
import MerchantBranchCredentialsService from "../../shared/service/MerchantBranchCredentialsService";
import {Checkbox} from "primereact/checkbox";

const defaultCredentials = {
    id: '',
    status: 'ACTIVE',
    merchantBranchId: '',
    clientId: '',
    clientSecret: '',
    masterDevice: false,
    orderDevice: false
};
export default class CredentialsSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            credentials: Object.assign({}, defaultCredentials),
            credentialsTemp: Object.assign({}, defaultCredentials)
        }
        this.merchantBranchCredentialsService = new MerchantBranchCredentialsService();
    }

    componentDidMount() {
        this.findByAppLicenceId();

    }
    findByAppLicenceId() {

        this.merchantBranchCredentialsService.findByAppLicenceId(this.props.getAppLicenseId()).then(response => {
            this.setState({credentials: Object.assign({}, response),credentialsTemp: Object.assign({}, response)});
        });
    }

    render() {
        return (
            <div class="p-col-12">
                <Fieldset legend={"Credentials"}>

                    <Row>
                        <Col md="12">
                            <AvGroup>
                                <Label for="tradingName">
                                    <Translate contentKey="merchantBranch.tradingName">
                                        Status</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="select"
                                    className="form-control"
                                    name="status"
                                    disabled={!this.state.credentialsSaveButtonRender}
                                    value={this.state.credentials.status}
                                    onChange={this.__handleChange}>
                                    {STATUS.map(element => (
                                        <option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>
                                    ))}
                                </AvField>
                            </AvGroup>
                        </Col>
                    </Row>
                    <Row>

                        <Col md="12">
                            <AvGroup>
                                <Label for="tradingName">
                                    <Translate
                                        contentKey="merchantBranch.tradingName">Client Id</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="clientId"
                                    disabled={false}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 255 characters in this field."
                                        }
                                    }}
                                    value={this.state.credentials.clientId}
                                    onChange={this.__handleChange}/>
                            </AvGroup>


                        </Col>
                    </Row>
                    <Row>

                        <Col md="12">
                            <AvGroup>
                                <Label for="tradingName">
                                    <Translate
                                        contentKey="merchantBranch.tradingName">Client Secret</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="clientSecret"
                                    disabled={false}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 255 characters in this field."
                                        }
                                    }}
                                    value={this.state.credentials.clientSecret}
                                    onChange={this.__handleChange}/>
                            </AvGroup>


                        </Col>
                    </Row>
                    <Row>

                        <Col md="12">
                            <AvGroup>
                                <Label for="tradingName">
                                    <Translate
                                        contentKey="merchantBranch.tradingName">Description</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="description"
                                    disabled={!this.state.credentialsSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 255 characters in this field."
                                        }
                                    }}
                                    value={this.state.credentials.description}
                                    onChange={this.__handleChange}/>
                            </AvGroup>


                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            <AvGroup>
                                <Label for="firstName">
                                    <Translate contentKey="department.firstName">MasterDevice?&nbsp;</Translate>
                                </Label>

                                <Checkbox onChange={(e) => this.setChecked("masterDevice", e.checked)}
                                          name="masterDevice"
                                          disabled={!this.state.credentialsSaveButtonRender}
                                          value={this.state.credentials.masterDevice}
                                          checked={this.state.credentials.masterDevice}></Checkbox>


                            </AvGroup>
                        </Col>

                    </Row>

                    <Row>
                        <Col md="12">
                            <AvGroup>
                                <Label for="firstName">
                                    <Translate contentKey="department.firstName">OrderDevice?&nbsp;</Translate>
                                </Label>

                                <Checkbox onChange={(e) => this.setChecked("orderDevice", e.checked)}
                                          name="orderDevice"
                                          disabled={!this.state.credentialsSaveButtonRender}
                                          value={this.state.credentials.orderDevice}
                                          checked={this.state.credentials.orderDevice}></Checkbox>


                            </AvGroup>
                        </Col>

                    </Row>


                    {!this.state.credentialsSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}} onClick={() => {
                            this.setState({credentialsSaveButtonRender: true});
                        }}>
                            <Translate contentKey="entity.action.add">Edit</Translate>
                        </Button>}
                    {this.state.credentialsSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}} onClick={() => {
                            this.saveCredentials();
                        }}>
                            <Translate contentKey="entity.action.add">Save</Translate>
                        </Button>
                    }

                    {this.state.credentialsSaveButtonRender && !GeneralUtils.isNullOrEmpty(this.state.credentials.id) &&
                        <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}} onClick={() => {
                            let obj1 = this.state.credentialsTemp;
                            this.setState({
                                credentials: Object.assign({}, obj1), credentialsSaveButtonRender: false
                            });
                        }}>
                            <Translate contentKey="entity.action.cancel">Cancel</Translate>
                        </Button>
                    }


                </Fieldset>
            </div>
        );

    }

    setChecked = (name, value) => {
        let credentials = this.state.credentials;
        credentials[name] = value;
        this.setState({credentials: credentials});
    }

    saveCredentials = () => {

        if (!GeneralUtils.isNullOrEmpty(this.state.credentials.id)) {
            this.merchantBranchCredentialsService.update(this.state.credentials)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {

                        this.setState({
                            credentials: response,
                            credentialsTemp: Object.assign({}, response),
                            credentialsSaveButtonRender: false
                        })

                    }
                });
        } else {
            let data =this.state.credentials ;
            data['appLicenceId'] =this.props.getAppLicenseId();
            data['merchantBranchId'] =this.props.getMerchantBranchId();
            if(data.status == null){
                data['status'] = "ACTIVE";
            }
            this.merchantBranchCredentialsService.create(data)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {

                        this.setState({
                            credentials: response,
                            credentialsTemp: Object.assign({}, response),
                            credentialsSaveButtonRender: false
                        })

                    }
                });
        }


    }


    setData = (data) => {
        let saveButtonRender = false;
        if (GeneralUtils.isNullOrEmpty(data.id)) {
            saveButtonRender = true;
        }

       data["status"] = "ACTIVE";
        this.setState({
            credentials: data,
            credentialsSaveButtonRender: saveButtonRender,
            credentialsTemp: Object.assign({}, data),
        });
    }

    clearData = () => {
        let obj = Object.assign({}, defaultCredentials);
        this.setState({credentials: obj});
    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let credentials = this.state.credentials;
        credentials[e.target.name] = value;
        this.setState({credentials: credentials});
    }

}



