import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class LicenseService extends Service {
    constructor() {
        super();
        this.baseURL = 'crm/merchant/license';
    }

    async getLicenses(branchId) {
        return fetch(SERVER_URL + this.baseURL + "/getLicenses/" + branchId, {
            headers: {'Content-Type': 'application/json'}
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response, true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async generateActivationCode(id) {
        return fetch(SERVER_URL + this.baseURL + "/activationcode/generate/" + id, {
            crossDomain: true,
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            }
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response, true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async updateApplicence(data) {
        return fetch(SERVER_URL + this.baseURL + "/applicence", {
            crossDomain: true,
            method: 'put',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            }
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response, true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async deleteApplicence(id) {
        return fetch(SERVER_URL + this.baseURL + "/applicence/" + id, {
            crossDomain: true,
            method: 'delete',
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            }
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response, true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }



}