import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';

import InputLabel from '@material-ui/core/InputLabel';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {AUTHORIZATION_HEADER, SERVER_URL} from '../../shared/utils/Constants';
import GeneralUtils from "../../shared/utils/GeneralUtils";
import {toast} from 'react-toastify';

import ReCAPTCHA from 'react-google-recaptcha';

import OutlinedInput from "@material-ui/core/OutlinedInput";
import PasswordResetInitMerchant from "./PasswordResetInitMerchant";
import PasswordResetFinishMerchant from "./PasswordResetFinishMerchant";
import LicenseService from "../../shared/service/LicenseService";
import MerchantBranchService from "../../shared/service/MerchantBranchService";
import SystemParamsService from "../../shared/service/SystemParamsService";
import Package from "../../../package.json";
import AdminUserService from "../../shared/service/AdminUserService";
import MerchantUserService from "../../shared/service/MerchantUserService";
import CurrentUserService from "../../shared/service/CurrentUserService";

global.appVersion = Package.version;

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 350,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    mainGuest: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        textTransform: 'none'
    }
});


class LoginMerchant extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            reCaptchaToken: null,
            userName: null,
            domainName: null,
            password: null,
            panel: 'login',
            loginShow: true,
            showCaptcha: true

        };
        this.merchantBranchService = new MerchantBranchService();
        this.licenseService = new LicenseService();
        this.systemParamsService = new SystemParamsService();
        this.merchantUserService = new MerchantUserService();
        this.adminUserService = new AdminUserService();
        this.currentUserService = new CurrentUserService();

    }

    componentDidMount() {

        this.systemParamsService.getSystemParams().then(response => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.setState({
                        showCaptcha: response.showCaptcha,
                    });
                }

            }
        );

        if (this.reCAPTCHA) {
            this.reCAPTCHA.reset();
        }

        //document.addEventListener('keydown', this.onKeyDown);

        if (GeneralUtils.getParameterByName('passwordChangeKey') !== null) {
            this.setState({
                panel: 'passwordResetFinish',
                passwordChangeKey: GeneralUtils.getParameterByName('passwordChangeKey')
            });
        }
        if (GeneralUtils.getParameterByName('activationKey') !== null) {
            this.setState({
                panel: 'passwordResetFinish',
                activationKey: GeneralUtils.getParameterByName('activationKey')
            });
        }


        if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("clientId"))) {
            this.setState({
                domainName: localStorage.getItem("clientId")
            });
        }


    }

    onChange = (reCaptchaToken) => {
        this.setState({reCaptchaToken: reCaptchaToken});
    }


    render() {
        if (this.state.panel === 'login') {
            return this.getLoginPanel();
        } else {
            return this.getPasswordResetPanel();
        }

    }

    getPasswordResetPanel = () => {
        const {classes} = this.props;
        return (
            <div className="login-background"
                 style={{backgroundImage: this.state.backgroundURL}}>
                <main className={classes.main}>
                    <CssBaseline/>
                    <Paper className={classes.paper}>
                        <img src={this.state.logo} alt="" style={{height: '90px'}}/>
                        <Typography component="h1" variant="h5">
                            {!GeneralUtils.isNullOrEmpty(this.state.activationKey) ? "User Activiation" : "Reset Password"}
                        </Typography>
                        <form className={classes.form}>
                            {this.getPasswordResetContent()}
                        </form>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={() => {
                                this.setState({panel: 'login'});
                                GeneralUtils.returnMainPage();
                            }}>
                            Back
                        </Button>
                    </Paper>
                </main>
            </div>
        );
    }

    getPasswordResetContent = () => {
        if (this.state.panel === 'passwordResetInit') {
            return <PasswordResetInitMerchant/>;
        } else if (this.state.panel === 'passwordResetFinish') {
            return <PasswordResetFinishMerchant activationKey={this.state.activationKey}
                                                passwordChangeKey={this.state.passwordChangeKey}/>;
        }
    }

    getLoginPanel = () => {
        const {classes} = this.props;
        return (
            <div className="login-background"
                 style={{backgroundImage: this.state.backgroundURL}}>
                <main className={classes.main}>
                    <CssBaseline/>
                    <Paper className={classes.paper}>
                        <div className="layout-logo">
                            <a href='#/'>
                                <img alt="Logo" src="assets/layout/images/dispatchio.png" style={{marginTop: '40px'}}/>
                            </a>
                        </div>
                        <Typography component="h1" variant="subtitle1">
                            {'V ' + global.appVersion + ''}

                        </Typography>
                        <form className={classes.form}>
                            <FormControl margin="normal" required fullWidth variant="outlined">
                                <InputLabel htmlFor="domainName">Store Name</InputLabel>
                                <OutlinedInput id="domainName" name="domainName" autoComplete="domainName"
                                               value={this.state.domainName}
                                               onChange={this.__handleChange} autoFocus/>
                            </FormControl>

                            <FormControl margin="normal" required fullWidth variant="outlined">
                                <InputLabel htmlFor="userName">User Name</InputLabel>
                                <OutlinedInput id="userName" name="userName"
                                               value={this.state.userName}
                                               onChange={this.__handleChange}/>
                            </FormControl>
                            <FormControl margin="normal" required fullWidth variant="outlined">
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <OutlinedInput name="password" type="password" id="password"
                                               autoComplete="current-password"
                                               value={this.state.password} onChange={this.__handleChange}/>
                            </FormControl>
                            <Button fullWidth size="small" className={classes.button}
                                    disabled={this.state.showCaptcha ? !this.state.reCaptchaToken : false}
                                    onClick={() => this.setState({panel: 'passwordResetInit'})}>
                                Forgot Password
                            </Button>

                            <span style={{alignItems: 'center'}}>
                                <ReCAPTCHA
                                    ref={(el) => {
                                        this.reCAPTCHA = el;
                                    }}
                                    hl="en"
                                    size="normal"
                                    sitekey="6LdsjTUfAAAAAH7TpbjBwaRYmvtPEV5YqJZeXdWK"
                                    onChange={this.onChange}/>
                            </span>

                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={this.state.showCaptcha ? !this.state.reCaptchaToken : false}
                                className={classes.button}
                                onClick={() => this.login()}>
                                Login
                            </Button>

                        </form>
                    </Paper>
                </main>
            </div>
        );
    }


    __handleChange = (e) => {
        let state = {};
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        state[e.target.name] = value;
        this.setState(state);
    }

    login = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.userName) || GeneralUtils.isNullOrEmpty(this.state.password) || GeneralUtils.isNullOrEmpty(this.state.domainName)) {
            return toast.warn("User name,Password and Store Name cannot be empty!")
        }

        window.location.hash = "/";
        localStorage.setItem("userType", "merchant");
        fetch(SERVER_URL + 'security/auth/login', {
            crossDomain: true,
            method: 'post',
            headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'},
            body: JSON.stringify({
                "domainName": this.state.domainName,
                "userName": this.state.userName,
                "password": this.state.password.toString(),
                "reCaptchaToken": this.state.reCaptchaToken
            })
        })
            .then(response => {
                if (response.ok) {
                    response.json().then((response) => {

                        if (!GeneralUtils.isNullOrEmpty(response["authCode"])) {
                            fetch(SERVER_URL + 'security/auth/token/code/' + response["authCode"], {
                                crossDomain: true,
                                method: 'post',
                                headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
                            })
                                .then(response => {
                                    if (response.ok) {
                                        response.json().then((response) => {


                                            GeneralUtils.setJwtToCookie(response.accessToken, response.refreshToken, response.expireDate);
                                            localStorage.setItem("clientId", this.state.domainName);


                                            this.merchantBranchService.getBranchIdByDomainName(this.state.domainName).then(responseBranch => {
                                                    if (!GeneralUtils.isNullOrEmpty(responseBranch)) {
                                                        //localStorage.setItem("hsmPrivilegeList", JSON.stringify(GeneralUtils.getPrivilegeListFromRoleList()));
                                                        this.licenseService.getLicenses(responseBranch).then(response => {
                                                            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type) && response.menuLicense) {
                                                                localStorage.setItem("merchantBranchId", responseBranch);

                                                                this.currentUserService.getCurrentMerchantUser().then(responseMerchantUser => {
                                                                    if (!GeneralUtils.isNullOrEmpty(responseMerchantUser) && GeneralUtils.isNullOrEmpty(responseMerchantUser.type)) {
                                                                        localStorage.setItem("hsmUser", JSON.stringify(responseMerchantUser));

                                                                        let merchantAccountId = JSON.stringify(responseMerchantUser.merchantAccountId);

                                                                        localStorage.setItem("domainName", responseMerchantUser.domainName);
                                                                        localStorage.setItem("merchantAccountId", merchantAccountId);
                                                                        localStorage.setItem("isAuthenticated", true);
                                                                        this.props.loginSuccess(responseMerchantUser.landingPage);
                                                                    }
                                                                })
                                                            }
                                                            else{
                                                                return toast.warn("Your license has been expired. Payment required. Call your Service Provider.");
                                                            }

                                                        });

                                                    }

                                                }
                                            );


                                        });

                                    } else {
                                        this.setState({userName: '', password: '', domainName: ''});
                                        GeneralUtils.notification(response);

                                    }
                                }).catch(error => {
                                GeneralUtils.notification(error);
                            });
                        }

                    });

                } else {
                    this.setState({userName: '', password: '', domainName: ''});
                    GeneralUtils.notification(response);

                }
            }).catch(error => {
            GeneralUtils.notification(error);
        });

    }

}


LoginMerchant.propTypes = {
    classes: PropTypes.object.isRequired,
    loginSuccess: PropTypes.func,
};

export default withStyles(styles)(LoginMerchant);
