import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class DeposImageService extends Service {
    constructor() {
        super();
        this.baseURL = 'product/image';
    }

    async findProductImagePath(barcode,name,type,branchId) {
        name = btoa(encodeURIComponent(name)); // Base64 ile encode et
        return fetch(SERVER_URL + this.baseURL + "/barcode/" + barcode+  "/name/" + name+"/type/"+type + "/branchId/" + branchId, {
            headers: { 'X-Softrame-BranchId' : localStorage.getItem("merchantBranchId"),'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }


    async getMigrationImages() {
        return fetch(SERVER_URL + this.baseURL+"/migration/1323", {
            crossDomain: true,
            method: 'put',
            headers: { 'X-Softrame-BranchId' : localStorage.getItem("merchantBranchId"),'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())},
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response,true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }




}