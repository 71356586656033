import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class MerchantBranchCredentialsService extends Service {
    constructor() {
        super();
        this.baseURL = 'security/merchant/credentials';
    }

    async getCurrentMerchantCredentials() {
        return fetch(SERVER_URL + this.baseURL + "/current", {
            crossDomain: true,
            method: 'get',
            headers: {'Content-Type': 'application/json',Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response, true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async findByAppLicenceId(appLicenceId) {
        return fetch(SERVER_URL + this.baseURL + "/appLicenceId/" + appLicenceId, {
            headers: { 'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

}