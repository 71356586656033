import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import Translate from "../../shared/language/Translate";
import GeneralUtils from '../../shared/utils/GeneralUtils';
import AvField from "../../shared/component/form/AvField";
import {Fieldset} from "primereact/fieldset";
import {toast} from "react-toastify";
import ModalConfirm from "../../shared/component/dialog/ModalConfirm";
import TaxColumn from "../../shared/component/dataTable/columns/TaxColumn.json";
import DataTableWithButton from "../../shared/component/dataTable/DataTableWithButton";
import {InputNumber} from "primereact/inputnumber";
import TaxService from "../../shared/service/TaxService";

const defaultTax = {
    id: '', name: '', status: 'ACTIVE', rate: null
};
export default class TaxSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tax: defaultTax,
            taxTemp: defaultTax,
            taxDetailsSaveButtonRender: true, taxList: []
        }
        this.taxService = new TaxService();
    }

    componentDidMount() {
        let obj1 = Object.assign({}, defaultTax);
        this.setState({
            tax: obj1, taxDetailsSaveButtonRender: false,
            taxTemp: Object.assign({}, obj1)
        });
        this.refresh();

    }

    refresh() {
        this.taxService.findAll().then(response => this.setState({
            taxList: response
        }));
    }

    render() {
        return (<div>
            <AvForm>
                {this.taxDetails()}
            </AvForm>
        </div>);
    }

    delete = () => {
        if (!GeneralUtils.isNullOrEmpty(this.state.tax.id)) {
            this.taxService.delete(this.state.tax.id)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        let obj1 = Object.assign({}, defaultTax);
                        this.setState({
                            tax: obj1, taxDetailsSaveButtonRender: false,
                            taxTemp: Object.assign({}, obj1)
                        });
                        this.refresh();
                    }
                });
        } 

        return undefined;
    }

    showChangeStatusConfirmDialog = (value) => {
        this.setState({
            displayShowStatusConfirmDialog: true,
            noticeResponse: "Are you sure to delete?"
        });
    }
    setChecked = (name, value) => {
        let tax = this.state.tax;
        tax[name] = value;
        this.setState({tax: tax});
    }

    taxDetails() {

        return (<div class="p-col-12">
            <Fieldset legend="Tax Details">
                <Row>
                    <ModalConfirm header='Warning' ok='OK' onOkClick={this.delete}
                                  onCancelClick={() => this.setState({displayShowStatusConfirmDialog: false})}
                                  show={this.state.displayShowStatusConfirmDialog}
                                  message={this.state.noticeResponse}/>

                    <Col md="12">
                        <Row>

                            <Col md="4">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="tax.tradingName">Name</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        disabled={!this.state.taxDetailsSaveButtonRender}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.tax.name}
                                        onChange={this.__handleChange}/>

                                </AvGroup>
                            </Col>
                        </Row>
                        <Row>


                            <Col md="4">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="tax.tradingName">Rate</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>

                                    <InputNumber
                                        className="table-responsive"
                                        name="rate"
                                        disabled={!this.state.taxDetailsSaveButtonRender}
                                        value={this.state.tax.rate}
                                        onChange={this.__handleChange}/>

                                </AvGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {GeneralUtils.checkPrivilege("post_product/tax") && this.state.taxDetailsSaveButtonRender == false && !GeneralUtils.isNullOrEmpty(this.state.tax.id) &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        this.setState({taxDetailsSaveButtonRender: true});
                    }}>
                        <Translate contentKey="entity.action.add">Edit</Translate>
                    </Button>}

                {GeneralUtils.checkPrivilege("post_product/tax") && this.state.taxDetailsSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        this.saveTax();
                    }}>
                        <Translate contentKey="entity.action.add">Save</Translate>
                    </Button>
                }

                {GeneralUtils.checkPrivilege("delete_product/tax/_id") && this.state.taxDetailsSaveButtonRender == false && !GeneralUtils.isNullOrEmpty(this.state.tax.id) &&

                    <Button color="outline-danger" style={{marginRight: '5px'}} onClick={() => {
                        this.showChangeStatusConfirmDialog(true)
                    }}>
                        <Translate contentKey="entity.action.add">Delete</Translate>
                    </Button>}

                {GeneralUtils.checkPrivilege("post_product/tax") && this.state.taxDetailsSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        let obj1 = this.state.taxTemp;
                        this.setState({
                            tax: Object.assign({}, obj1), taxDetailsSaveButtonRender: false
                        });
                    }}>
                        <Translate contentKey="entity.action.cancel">Cancel</Translate>
                    </Button>
                }
            </Fieldset>

            <Fieldset legend="Taxes">
                {GeneralUtils.checkPrivilege("post_product/tax") &&
                    <Button color="outline-primary" style={{marginBottom: '5px'}} onClick={() => {
                        this.addNew();
                    }}>
                        <Translate contentKey="entity.action.add">Add</Translate>
                    </Button>
                }

                {GeneralUtils.checkPrivilege("get_product/tax") &&
                    <DataTableWithButton ref={(elem) => this.refDataTable = elem} fields={TaxColumn.fields}
                                         objectName={TaxColumn.name}
                                         data={this.state.taxList}
                                         rows={5}
                                         selectionMode="single" onDataSelect={this.onDataSelect}
                                         loading={this.state.loading}/>

                }
            </Fieldset>
        </div>)
    };

    onDataSelect = (data) => {
            this.taxService.findById(data.id).then(response => {
                this.setState({tax: response, taxDetailsSaveButtonRender: false, taxTemp: Object.assign({}, response)});

            });

    }
    saveTax = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.tax.name) ) {
            return toast.warn("Please fill the required fields!");
        } else {
            if (!GeneralUtils.isNullOrEmpty(this.state.tax.id)) {
                this.taxService.update(this.state.tax)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                taxDetailsSaveButtonRender: false, tax: response,
                                taxTemp: Object.assign({}, response)

                            });
                            this.refresh();
                        }
                    });
            } else {
                this.taxService.create(this.state.tax)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                taxDetailsSaveButtonRender: false, tax: response,
                                taxTemp: Object.assign({}, response)
                            });
                            this.refresh();
                        }
                    });
            }
        }

    }

    addNew() {
        let obj1 = Object.assign({}, defaultTax);
        this.setState({
            tax: obj1, taxDetailsSaveButtonRender: true, taxTemp: Object.assign({}, obj1)
        });
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let tax = this.state.tax;
        tax[e.target.name] = value;
        this.setState({tax: tax});
    }

}

