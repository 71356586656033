import React, {Component} from 'react';
import PropTypes from 'prop-types';
import GeneralUtils from "../../utils/GeneralUtils";
import PageSettingsService from "../../service/PageSettingsService";
import {Menubar} from 'primereact/menubar';
import MerchantBranchService from "../../service/MerchantBranchService";
import { withRouter } from 'react-router-dom';

export   class AppTopbarMember extends Component {
    constructor(props) {
        super(props);

        this.state = {
            noticeCount: 0,
            header: null,
            homeName: 'Home',
            domainName:null

        };
        this.pageSettingsService = new PageSettingsService();
        this.merchantBranchService = new MerchantBranchService();
    }


    componentDidMount = async () => {
        let branchName = GeneralUtils.getDomainName();
        if (!GeneralUtils.isNullOrEmpty(branchName) && branchName!="admin") {
                const response = (await this.merchantBranchService.getBranchIdByDomainName(branchName));
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type) && response != 0) {
                    localStorage.setItem("merchantBranchId", response);
                    this.getImageAndBusinessName(response);
                } else {
                    this.getImageAndBusinessName(-1);
                }



        } else {
            this.getImageAndBusinessName(-1);
        }
    }

    setMarketPlaceHeader(){
        if (GeneralUtils.isMobile()) {
            this.setState({header: "assets/layout/images/marketplace_mobile.png"});
        } else {
            this.setState({header: "assets/layout/images/marketplace_web.png"});
        }
    }
    getImageAndBusinessName(merchantBranchId) {
        if (GeneralUtils.isNullOrEmpty(GeneralUtils.getDomainName()) || merchantBranchId == -1) {

           this.setMarketPlaceHeader()

        } else {

            if (!GeneralUtils.isNullOrEmpty(merchantBranchId)) {
                this.merchantBranchService.getStoreInfo(merchantBranchId).then(response => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            if (!GeneralUtils.isNullOrEmpty(response.tradingName)) {
                                this.setState({homeName: response.tradingName, domainName: response.domainName});
                            }

                        }
                    }
                )
            }

            if (!GeneralUtils.isNullOrEmpty(merchantBranchId)) {
                let imageType = GeneralUtils.isMobile() ? "MOBILE" : "HEADER";

                this.pageSettingsService.getImage(merchantBranchId, imageType).then(response => {
                    if (!GeneralUtils.isNullOrEmpty(response) && !GeneralUtils.isNullOrEmpty(response.file)) {
                        this.setState({header: GeneralUtils.convertForImageSource(response.file, response.fileType, response.fileName)});
                    }
                    else{
                       this.setMarketPlaceHeader()
                    }
                });
            }

        }
    }


    render() {
        var itemsLogin = [];

        var items = [];
        if (!GeneralUtils.isNullOrEmpty(GeneralUtils.getDomainName())) {
            items.push({
                label: this.state.homeName,
                icon: 'pi pi-fw pi-home',
                command: (e) => {
                    this.setState({loading: true});
                    if(this.state.homeName == "Home"){
                        window.location.href = '/'
                    }
                    else{
                        if (GeneralUtils.isServedInWebMarketUrl()) {
                            window.location.href = '/'+this.state.domainName
                        }
                        else{
                            window.location.href = '/'
                        }

                    }

                }
            });

            if (GeneralUtils.isServedInWebMarketUrl()) {
                items.push({
                    label: 'Market Place',
                    icon: 'pi pi-fw pi-home',
                    command: (e) => {
                        localStorage.setItem("merchantBranchId", -1);
                        this.setMarketPlaceHeader()
                        window.location.href = "/";

                    }
                })
            }
            if (GeneralUtils.isServedInWebMarketUrl()) {
                itemsLogin.push({
                    label: 'Market Place',
                    icon: 'pi pi-fw pi-home',
                    command: (e) => {
                        localStorage.setItem("merchantBranchId", -1);
                        this.setMarketPlaceHeader()
                        window.location.href = "/";


                    }
                })
            }


        }

        itemsLogin.push({
            label: "LOGIN",
            icon: 'pi pi-fw pi-user',
            disabled:window.location.href.indexOf("?")>0,
            command: (e) => {
                window.location.href = "/login";
            }

        })
        items.push(
            {
                label: GeneralUtils.getFirstName(),
                icon: 'pi pi-fw pi-user',
                items: [
                    {
                        label: 'Account Details',
                        command: (e) => {
                            window.location.href = "/accountDetails";
                        }

                    },
                    {
                        label: 'Payment Methods',
                        command: (e) => {
                            window.location.href =  '/paymentMethods'
                        }

                    },
                    {
                        label: 'Addresses',
                        command: (e) => {
                            window.location.href = '/address'
                        }

                    },
                    {
                        label: 'Orders',
                        command: (e) => {
                            window.location.href = "/myOrders";
                        }

                    },
                    {
                        label: 'Complaint / Suggestion',
                        command: (e) => {
                            window.location.href = "/complaintSuggestion";
                        }

                    },

                    {
                        label: 'Log out',
                        command: (e) => {
                            localStorage.removeItem(localStorage.getItem("merchantBranchId"));
                            this.props.logout();
                        }
                    }
                ]
            })
        ;

        let height = 360;
        let width = "105%";
        let maxWidth = "1900px";
        let marginRight = "20px"
        if (GeneralUtils.isMobile()) {
            height = 300;
            width = "100%"
            maxWidth = "100%";
            marginRight = "2px";
        }

        return (

            <div>


                    <div >

                        {
                            GeneralUtils.isTestEnvironment() &&

                            <div id="testEnvironmentDiv"
                                 style={{
                                     backgroundColor: "red",
                                     textAlign: "center",
                                     width:"105%",
                                     height: "40px",
                                     fontSize: "25px"
                                 }}>Test
                                Environment!</div>
                        }

                        <div style={{float: 'right', position: 'static !important',marginRight:marginRight}}>

                            {GeneralUtils.isAuthenticated() == false &&
                                <a>
                                    <Menubar model={itemsLogin}/>
                                </a>

                            }
                            {GeneralUtils.isAuthenticated() == true &&
                                <a>
                                    <Menubar className={"p-menuitem-text"} model={items}/>
                                </a>
                            }
                        </div>

                    </div>

                    {this.state.header != null &&
                        <div style={{textAlign: "center"}}>
                            <img src={this.state.header}
                                 width={width}
                                 height={height + "px"}
                                 maxWidth={maxWidth}
                                 />
                        </div>


                    }




            </div>
        )
            ;
    }
}

AppTopbarMember
    .propTypes = {
    onToggleMenu: PropTypes.func.isRequired,
    logout: PropTypes.func
};

AppTopbarMember
    .defaultProps = {
    onToggleMenu: null
};

withRouter(AppTopbarMember);
